import { useEffect, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import {
    Close as CloseIcon,
    Add as AddIcon,
    DeleteSweep as DeleteSweepIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material';

import moment from 'moment';

import {
    CustomDialog,
    CustomButton,
    CustomList,
    CustomInput,
    CustomSelect,
    CustomDatePicker,
} from 'UI';

const validateFilters = (filters) => {
    const errors = [];

    for (let index = 0; index < filters.length; index++) {
        const { typeofField, value } = filters[index];
        if (['string', 'number', 'date'].includes(typeofField) && !value) {
            errors.push({ errorMessage: 'Chưa nhập giá trị!', index });
        }
        if (typeofField === 'array' && value.length === 0) {
            errors.push({ errorMessage: 'Chưa chọn giá trị!', index });
        }
        if (typeofField === 'date') {
            const year = moment(value).year();
            if (year > 2099)
                errors.push({
                    errorMessage: 'Không được vượt quá năm 2099!',
                    index,
                });
            if (year < 2000)
                errors.push({
                    errorMessage: 'Không được nhỏ hơn năm 2000!',
                    index,
                });
        }
    }
    return errors;
};

function CustomStandardInput({
    name = 'name',
    value,
    onChange = () => {},
    ...otherProps
}) {
    return (
        <CustomInput
            name={name}
            id={name + '-inputId'}
            value={value}
            onChange={onChange}
            {...otherProps}
        />
    );
}
function CustomStandardSelect({
    name = 'name',
    options = [],
    value,
    onChange = () => {},
    multiple = false,
    ...otherProps
}) {
    return (
        <CustomSelect
            name={name}
            id={name + '-selectId'}
            initialText={
                <Typography variant="caption" color="primary">
                    {`Chọn ${multiple ? 'các' : 'một'} giá trị`}
                </Typography>
            }
            disabale
            options={options}
            multiple={multiple}
            value={value}
            onChange={onChange}
            {...otherProps}
        />
    );
}
export default function FilterDialog({
    open = false,
    onClose = () => {},
    initialValue = [],
    onSubmit = () => {},
    filterData = {},
    ...otherProps
}) {
    const [filterModel, setFilterModel] = useState([]);
    // apply current filter if present, only run once!
    useEffect(() => {
        let getData;
        if (open) {
            getData = setTimeout(() => {
                setFilterModel(
                    initialValue.length > 0
                        ? initialValue
                        : [
                              {
                                  id: Math.random().toString(36).slice(2, 11),
                                  connectType: 'and',
                                  field: Object.keys(filterData)[0],
                                  typeofField:
                                      filterData[Object.keys(filterData)[0]]
                                          .typeofField,
                                  type: filterData[Object.keys(filterData)[0]]
                                      .initialType,
                                  value: filterData[Object.keys(filterData)[0]]
                                      .initialValue,
                              },
                          ]
                );
            }, 200);
        }
        return () => {
            clearTimeout(getData);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [errors, setErrors] = useState([]);

    const handleSubmit = () => {
        let validation = validateFilters(filterModel);
        if (validation.length > 0) {
            setErrors(validation);
            return;
        } else {
            onSubmit(filterModel);
            onClose();
        }
    };

    const handleAddData = () => {
        setFilterModel((data) => {
            let updatedData = [...data];
            return [
                ...updatedData,
                {
                    id: Math.random().toString(36).slice(2, 11),
                    connectType: 'and',
                    field: Object.keys(filterData)[0],
                    typeofField:
                        filterData[Object.keys(filterData)[0]].typeofField,
                    type: filterData[Object.keys(filterData)[0]].initialType,
                    value: filterData[Object.keys(filterData)[0]].initialValue,
                },
            ];
        });
    };
    const handleDeleteData = (id) => {
        setFilterModel((data) => {
            let updatedData = [...data];
            return updatedData.filter((i) => i.id !== id);
        });
    };

    const handleChangeData = (field, id, value) => {
        setFilterModel((data) => {
            let updatedData = [...data];
            updatedData = updatedData.map((item) =>
                item.id === id
                    ? {
                          ...item,
                          [field]: value,
                      }
                    : item
            );
            return updatedData;
        });
    };
    const handleError = (index) => {
        setErrors((errors) => {
            let updatedErrors = [...errors];
            updatedErrors = updatedErrors.filter((i) => i.index !== index);
            return updatedErrors;
        });
    };
    const handleChangeField = (id, newValue) => {
        setFilterModel((data) => {
            let updatedData = [...data];
            let typeofField = filterData?.[newValue]?.typeofField || '';
            let type = filterData?.[newValue]?.initialType || '';
            let value = filterData?.[newValue]?.initialValue || '';
            updatedData = updatedData.map((item) =>
                item.id === id
                    ? {
                          ...item,
                          field: newValue,
                          typeofField,
                          type,
                          value,
                      }
                    : item
            );
            return updatedData;
        });
    };

    let handleSwitchConnectType = (id, connectType) => {
        handleChangeData(
            'connectType',
            id,
            connectType === 'and' ? 'or' : 'and'
        );
    };

    if (open) {
        return (
            <CustomDialog
                id="filter-data-custom-dialog"
                title={'Bộ lọc dữ liệu'}
                open={open}
                onClose={onClose}
                maxWidth="md"
                hideNoButton
                labelYesButton={filterModel.length > 0 ? 'Lọc' : 'Không lọc'}
                onYes={handleSubmit}
                DialogContentProps={{ sx: { pt: 0, px: { xs: 1, sm: 2 } } }}>
                <CustomList
                    id="filter-list-listId"
                    items={filterModel.map(
                        (
                            {
                                id,
                                connectType = 'and',
                                field: keyName,
                                typeofField: operatorType,
                                type: operatorValue,
                                value,
                            },
                            index
                        ) => {
                            let errMess =
                                errors.find((i) => i.index === index)
                                    ?.errorMessage ?? '';
                            let showError = Boolean(errMess);
                            let isDisabledInputValue =
                                !operatorValue || !keyName;

                            return {
                                name: id,
                                label: null,
                                subLabel: (
                                    <Stack
                                        direction="row"
                                        alignItems={
                                            showError ? 'center' : 'flex-end'
                                        }
                                        width="100%"
                                        minWidth="600px"
                                        spacing={1}>
                                        <CustomButton
                                            {...getDeleteButtonProps(id)}
                                            onClick={() => handleDeleteData(id)}
                                        />
                                        <CustomButton
                                            id={id + '-swap-connectType-button'}
                                            disabled={index === 0}
                                            {...getConnectTypeButtonProps(
                                                connectType
                                            )}
                                            onClick={() =>
                                                handleSwitchConnectType(
                                                    id,
                                                    connectType
                                                )
                                            }
                                        />
                                        <CustomStandardSelect
                                            name="field"
                                            options={Object.keys(filterData)}
                                            formatOption={(option) => ({
                                                name: option,
                                                label:
                                                    filterData?.[option]
                                                        ?.label ?? '',
                                                subLabel: null,
                                            })}
                                            value={keyName}
                                            onChange={(newField) => {
                                                if (newField === keyName) {
                                                    return;
                                                }
                                                handleChangeField(id, newField);
                                                handleError(index);
                                            }}
                                            helperText={showError ? ' ' : ''}
                                        />
                                        <CustomStandardSelect
                                            name="type"
                                            options={getTypeValue(operatorType)}
                                            disabled={!keyName || !operatorType}
                                            formatOption={(option) => ({
                                                name: option,
                                                label: getTypeLabel(option),
                                                subLabel: null,
                                            })}
                                            value={operatorValue}
                                            onChange={(newType) =>
                                                handleChangeData(
                                                    'type',
                                                    id,
                                                    newType
                                                )
                                            }
                                            helperText={showError ? ' ' : ''}
                                        />
                                        <>
                                            {operatorType === 'array' && (
                                                <CustomStandardSelect
                                                    name={id}
                                                    multiple={true}
                                                    disabled={
                                                        isDisabledInputValue
                                                    }
                                                    value={
                                                        value === ''
                                                            ? []
                                                            : value
                                                    }
                                                    onChange={(newValue) => {
                                                        handleChangeData(
                                                            'value',
                                                            id,
                                                            newValue
                                                        );
                                                        handleError(index);
                                                    }}
                                                    options={
                                                        filterData?.[keyName]
                                                            ?.options ?? []
                                                    }
                                                    formatOption={(option) => ({
                                                        name: option,
                                                        label:
                                                            filterData?.[
                                                                keyName
                                                            ]?.getOptionLabel(
                                                                option
                                                            ) ?? option,
                                                        subLabel: null,
                                                    })}
                                                />
                                            )}
                                            {operatorType === 'boolean' && (
                                                <CustomStandardSelect
                                                    name={id}
                                                    multiple={true}
                                                    disabled={
                                                        isDisabledInputValue
                                                    }
                                                    value={
                                                        value === ''
                                                            ? []
                                                            : value
                                                    }
                                                    onChange={(newValue) => {
                                                        handleChangeData(
                                                            'value',
                                                            id,
                                                            newValue
                                                        );
                                                        handleError(index);
                                                    }}
                                                    options={
                                                        filterData?.[keyName]
                                                            ?.options ?? []
                                                    }
                                                    formatOption={(option) => ({
                                                        name: option,
                                                        label:
                                                            filterData?.[
                                                                keyName
                                                            ]?.getOptionLabel(
                                                                option
                                                            ) ?? option,
                                                        subLabel: null,
                                                    })}
                                                />
                                            )}
                                            {(operatorType === 'string' ||
                                                operatorType === 'number') && (
                                                <CustomStandardInput
                                                    name={id}
                                                    operatorType={operatorType}
                                                    disabled={
                                                        isDisabledInputValue
                                                    }
                                                    value={value}
                                                    onChange={(newValue) => {
                                                        handleChangeData(
                                                            'value',
                                                            id,
                                                            newValue
                                                        );
                                                        handleError(index);
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault();
                                                            handleSubmit();
                                                        }
                                                    }}
                                                    {...(operatorType ===
                                                        'number' && {
                                                        type: 'number',
                                                        inputProps: {
                                                            min: 0,
                                                        },
                                                    })}
                                                    error={errMess || ''}
                                                />
                                            )}
                                            {operatorType === 'date' && (
                                                <CustomDatePicker
                                                    name={id}
                                                    disabled={
                                                        isDisabledInputValue
                                                    }
                                                    value={value}
                                                    onChange={(newValue) => {
                                                        handleChangeData(
                                                            'value',
                                                            id,
                                                            newValue
                                                        );
                                                        handleError(index);
                                                    }}
                                                    error={errMess || ''}
                                                />
                                            )}
                                        </>
                                    </Stack>
                                ),
                            };
                        }
                    )}
                />
                <Stack
                    minWidth={600}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    mt={2}>
                    <CustomButton
                        id="remove-filters-button"
                        variant="outlined"
                        color="warning"
                        disabled={filterModel.length === 0}
                        onClick={() => setFilterModel([])}
                        startIcon={<DeleteSweepIcon />}
                        children="Xóa tất cả"
                    />
                    <CustomButton
                        id="add-filter-button"
                        variant="outlined"
                        color="success"
                        onClick={handleAddData}
                        startIcon={<AddIcon />}
                        children="Thêm"
                    />
                </Stack>
            </CustomDialog>
        );
    } else return null;
}

const getTypeLabel = (opt) => {
    const typeLabelMapping = {
        contains: 'có chứa',
        notContains: 'không chứa',
        startsWith: 'bắt đầu với',
        endsWith: 'kết thúc với',
        matchExact: 'bằng đúng',
        '!=': 'không =',
        in: 'gồm',
        nin: 'không gồm',
        isSameDate: 'là ngày',
        isNotSameDate: 'không phải ngày',
        isBeforeDate: 'trước ngày',
        isAfterDate: 'sau ngày',
    };
    return typeLabelMapping?.[opt] ?? opt;
};
const getTypeValue = (operatorType) => {
    const typeValueMapping = {
        string: [
            'contains',
            'notContains',
            'startsWith',
            'endsWith',
            'matchExact',
        ],
        number: ['=', '!=', '>', '>=', '<', '<='],
        date: ['isSameDate', 'isNotSameDate', 'isBeforeDate', 'isAfterDate'],
        array: ['in', 'nin'],
        boolean: ['in', 'nin'],
    };
    return typeValueMapping?.[operatorType] ?? [];
};
let getConnectTypeButtonProps = (connectType) => {
    return {
        variant: 'outlined',
        color: 'secondary',
        sx: { py: 0.25 },
        startIcon: connectType !== 'and' && (
            <CheckBoxOutlineBlankIcon sx={{ color: 'transparent' }} />
        ),
        endIcon: connectType !== 'or' && (
            <CheckBoxOutlineBlankIcon sx={{ color: 'transparent' }} />
        ),
        children: connectType === 'and' ? 'và' : 'hoặc',
    };
};

let getDeleteButtonProps = (id) => {
    return {
        id: id + '-delete-button',
        color: 'error',
        useIconButton: true,
        children: <CloseIcon fontSize="small" />,
    };
};
