import { Stack } from '@mui/material';
import {
    ListAlt as ListAltIcon,
    GridViewOutlined as GridViewOutlinedIcon,
    GridViewSharp as GridViewSharpIcon,
} from '@mui/icons-material';

import { CustomButton } from 'UI';

export default function ViewTypeButton({
    value = 'vertical',
    onChange = () => {},
    ...otherProps
}) {
    return (
        <Stack
            direction="row"
            alignItems="center"
            sx={{
                borderRadius: 5,
                borderWidth: '0.5px',
                borderStyle: 'solid',
                borderColor: 'disabled.light',
            }}>
            <CustomButton
                id="vertical-toggleButtonId"
                color="disabled"
                useIconButton
                disableEffect={value !== 'vertical'}
                onClick={onChange}
                sx={{ ...getSXButton(value === 'vertical') }}
                aria-label="toggle-button-vertical">
                <Icon
                    IconName={
                        value === 'vertical'
                            ? GridViewSharpIcon
                            : GridViewOutlinedIcon
                    }
                />
            </CustomButton>
            <CustomButton
                id="horizontal-toggleButtonId"
                color="disabled"
                useIconButton
                disableEffect={value !== 'horizontal'}
                onClick={onChange}
                sx={{ ...getSXButton(value === 'horizontal') }}
                aria-label="toggle-button-horizontal">
                <Icon IconName={ListAltIcon} />
            </CustomButton>
        </Stack>
    );
}

const Icon = ({ IconName }) => {
    let MuiIcon = IconName;
    return (
        <MuiIcon
            sx={{
                color: 'text.secondary',
                width: 18,
                height: 18,
            }}
        />
    );
};

const getSXButton = (value = true) => ({
    borderRadius: 5,
    borderWidth: '0.5px',
    borderStyle: 'solid',
    borderColor: value ? 'disabled.light' : 'transparent',
    backgroundColor: value ? 'white' : 'transparent',
    ':hover': {
        backgroundColor: value ? 'rgba(0,0,0,0.08)' : 'transparent',
    },
    p: 0.75,
    transition: 'background-color 0.3s ease-in-out',
});
