import {
    Stack,
    Card,
    CardContent,
    CardActions,
    Typography,
    Link,
    useMediaQuery,
} from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { lighten } from '@mui/material/styles';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { recentWatchProdActions } from 'store/recentWatchProd-slice.js';

import { CustomAvatar, CustomButton } from 'UI';

export default function ProductCard({
    prodData = {},
    viewType = 'vertical',
    ...otherProps
}) {
    let {
        _id = Math.random().toString(36).slice(2, 11),
        category = [],
        sku = '',
        slug = '',
        webName = '',
        primaryImage = {},
        specification = '',
    } = prodData;

    let dispatch = useDispatch();
    const navigate = useNavigate();

    const isSmallerSmScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('sm')
    );
    const isBiggerMdScreen = useMediaQuery((theme) =>
        theme.breakpoints.up('md')
    );
    const isBiggerLgScreen = useMediaQuery((theme) =>
        theme.breakpoints.up('lg')
    );

    let categoryMaxLevelData =
        category.length > 0
            ? category.reduce((max, current) => {
                  if (!max) {
                      return current;
                  }
                  return current.level > max.level ? current : max;
              }, null)
            : null;
    let categoryName = categoryMaxLevelData?.name || null;
    let categorySlug = categoryMaxLevelData?.slug;

    let href = `/san-pham${slug}`;

    const handleClick = (e) => {
        e.preventDefault();
        dispatch(recentWatchProdActions.addRecentWatch(sku));
        navigate(href);
    };

    if (viewType === 'vertical') {
        let hasMoreContent = isBiggerLgScreen || isSmallerSmScreen;
        return (
            <Card
                variant="outlined"
                key={_id + '-card-product-item'}
                sx={{
                    borderColor: 'grey.200',
                    borderRadius: 2,
                    '&: hover': {
                        borderColor: 'primary.light',
                        boxShadow: 2,
                    },
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: { xs: 370, sm: 350, lg: 390 },
                    transition: 'all 0.3s ease-in-out',
                }}>
                <Link component={RouterLink} to={href} onClick={handleClick}>
                    <CustomAvatar
                        variant="square"
                        src={primaryImage?.mainSrc || ''}
                        alt={primaryImage?.altText || ''}
                        avatarSize={160}
                        sx={{ mx: 'auto', py: 1, px: 0 }}
                    />
                </Link>
                <Stack height="100%" justifyContent="space-between">
                    {specification && (
                        <Stack spacing={1} ml={-2}>
                            <Typography
                                noWrap
                                sx={{
                                    fontSize: { xs: 12, lg: 13 },
                                    backgroundColor: 'aliceblue',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    p: '5px 20px 5px 32px',
                                    borderRadius: 4,
                                    color: 'text.secondary',
                                    width: 'fit-content',
                                    maxWidth: '80%',
                                }}>
                                {specification}
                            </Typography>
                        </Stack>
                    )}
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            mt: 0,
                            py: 1,
                            px: { xs: 1.75, sm: 2 },
                        }}>
                        <Typography
                            component="h3"
                            gutterBottom
                            sx={{
                                maxHeight: hasMoreContent ? 74 : 48,
                                fontSize: { xs: 13, sm: 16 },
                                lineHeight: 1.5,
                                fontWeight: 500,
                                textTransform: 'initial',
                                textAlign: 'left',
                                whiteSpace: 'initial',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: hasMoreContent ? '3' : '2',
                                WebkitBoxOrient: 'vertical',
                                color: 'primary.main',
                                '&:hover': { color: 'text.link' },
                            }}>
                            <Link
                                component={RouterLink}
                                to={href}
                                onClick={handleClick}
                                underline="none"
                                sx={{
                                    color: 'inherit',
                                    '&:hover': { color: 'text.link' },
                                }}>
                                {webName}
                            </Link>
                        </Typography>
                        <Typography
                            component="h6"
                            gutterBottom
                            sx={{
                                maxHeight: hasMoreContent ? 44 : 24,
                                fontSize: { xs: 13, sm: 15 },
                                lineHeight: 1.5,
                                textTransform: 'initial',
                                textAlign: 'left',
                                whiteSpace: 'initial',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: hasMoreContent ? '2' : '1',
                                WebkitBoxOrient: 'vertical',
                                color: 'text.secondary',
                                '&:hover': { color: 'text.secondary' },
                            }}>
                            <Link
                                component={RouterLink}
                                to={categorySlug}
                                sx={{
                                    pt: 0.5,
                                    color: 'inherit',
                                    ':hover': {
                                        color: 'text.secondary',
                                        textDecoration: 'underline',
                                    },
                                }}
                                underline="none">
                                {categoryName}
                            </Link>
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing sx={{ px: '10px' }}>
                        <CustomButton
                            id={_id + '-view-detail-custom-button'}
                            variant="contained"
                            sx={(theme) => {
                                let newBackgroundColor = lighten(
                                    theme.palette.primary.main,
                                    0.1
                                );
                                return {
                                    borderRadius: 5,
                                    fontWeight: 600,
                                    mb: 1,
                                    minWidth: '120px',
                                    py: 0.5,
                                    backgroundColor: newBackgroundColor,
                                    color: 'white',
                                    ':hover': {
                                        boxShadow: 1,
                                        color: 'white',
                                        backgroundColor: newBackgroundColor,
                                    },
                                    '&:active': {
                                        boxShadow: 'none',
                                        backgroundColor: newBackgroundColor,
                                        color: 'white',
                                    },
                                    '&:focus': {
                                        boxShadow: 1,
                                        backgroundColor: newBackgroundColor,
                                        color: 'white',
                                    },
                                };
                            }}
                            endIcon={<ChevronRightIcon sx={{ mx: 0 }} />}
                            fullWidth
                            onClick={handleClick}
                            children="Xem thông tin"
                        />
                    </CardActions>
                </Stack>
            </Card>
        );
    }

    let hasMoreContent = isBiggerMdScreen || isSmallerSmScreen;
    return (
        <Card
            variant="outlined"
            key={_id + '-card-product-item'}
            sx={{
                borderColor: 'grey.200',
                borderRadius: 2,
                '&: hover': {
                    borderColor: 'primary.light',
                    boxShadow: 2,
                },
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: { xs: 'auto', md: 240 },
                transition: 'all 0.3s ease-in-out',
            }}>
            <Link component={RouterLink} to={href} onClick={handleClick}>
                <CustomAvatar
                    variant="square"
                    src={primaryImage?.mainSrc || ''}
                    alt={primaryImage?.altText || ''}
                    avatarSize={200}
                    sx={{ mx: 'auto', p: 1 }}
                />
            </Link>
            <Stack height="100%" justifyContent="flex-start">
                {specification && (
                    <Stack spacing={1} ml={0}>
                        <Typography
                            noWrap
                            sx={{
                                fontSize: { xs: 12, lg: 13 },
                                backgroundColor: 'aliceblue',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                p: '10px 0 8px 18px',
                                borderRadius: '0 7px 0 7px',
                                maxWidth: '100%',
                                color: 'text.secondary',
                            }}>
                            {specification}
                        </Typography>
                    </Stack>
                )}
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 0,
                        mt: -1,
                        pt: 2,
                        pb: 1,
                        px: { xs: 1.75, sm: 2 },
                    }}>
                    <Typography
                        component="h3"
                        gutterBottom
                        sx={{
                            maxHeight: hasMoreContent ? 74 : 48,
                            fontSize: { xs: 13, sm: 16 },
                            lineHeight: 1.5,
                            fontWeight: 500,
                            textTransform: 'initial',
                            textAlign: 'left',
                            whiteSpace: 'initial',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: hasMoreContent ? '3' : '2',
                            WebkitBoxOrient: 'vertical',
                            color: 'primary.main',
                            '&:hover': { color: 'text.link' },
                        }}>
                        <Link
                            component={RouterLink}
                            to={href}
                            onClick={handleClick}
                            underline="none"
                            sx={{
                                color: 'inherit',
                                '&:hover': { color: 'text.link' },
                            }}>
                            {webName}
                        </Link>
                    </Typography>
                    <Typography
                        component="h6"
                        gutterBottom
                        sx={{
                            maxHeight: hasMoreContent ? 44 : 24,
                            fontSize: { xs: 13, sm: 15 },
                            lineHeight: 1.5,
                            textTransform: 'initial',
                            textAlign: 'left',
                            whiteSpace: 'initial',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: hasMoreContent ? '2' : '1',
                            WebkitBoxOrient: 'vertical',
                            color: 'text.secondary',
                            '&:hover': { color: 'text.secondary' },
                        }}>
                        <Link
                            component={RouterLink}
                            to={categorySlug}
                            sx={{
                                pt: 0.5,
                                color: 'inherit',
                                ':hover': {
                                    color: 'text.secondary',
                                    textDecoration: 'underline',
                                },
                            }}
                            underline="none">
                            {categoryName}
                        </Link>
                    </Typography>
                </CardContent>
                <CardActions disableSpacing sx={{ px: '10px' }}>
                    <CustomButton
                        id={_id + '-view-detail-custom-button'}
                        variant="contained"
                        sx={(theme) => {
                            let newBackgroundColor = lighten(
                                theme.palette.primary.main,
                                0.1
                            );
                            return {
                                borderRadius: 5,
                                fontWeight: 600,
                                mb: 1,
                                minWidth: '120px',
                                py: 0.5,
                                backgroundColor: newBackgroundColor,
                                color: 'white',
                                ':hover': {
                                    boxShadow: 1,
                                    color: 'white',
                                    backgroundColor: newBackgroundColor,
                                },
                                '&:active': {
                                    boxShadow: 'none',
                                    backgroundColor: newBackgroundColor,
                                    color: 'white',
                                },
                                '&:focus': {
                                    boxShadow: 1,
                                    backgroundColor: newBackgroundColor,
                                    color: 'white',
                                },
                            };
                        }}
                        endIcon={<ChevronRightIcon sx={{ mx: 0 }} />}
                        fullWidth={false}
                        onClick={handleClick}
                        children="Xem thông tin"
                    />
                </CardActions>
            </Stack>
        </Card>
    );
}
