import { useState, useContext, useCallback, useEffect } from 'react';
import {
    Stack,
    Box,
    Typography,
    Collapse,
    useMediaQuery,
    Drawer,
} from '@mui/material';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    FilterListOutlined as FilterListOutlinedIcon,
    Search as SearchIcon,
    Clear as ClearIcon,
} from '@mui/icons-material';

import { CustomInput, CustomList, CustomCheckbox, CustomButton } from 'UI';

import { ProductContext } from 'context/product-context';

import ActiveFilterBox from 'shop/components/ActiveFilterBox';

import useDebounce from 'hooks/useDebounce';

function CustomListItem({ keyName = '', ...otherProps }) {
    const { dataFilter, deleteFilter, applyFilters, filteringChips } =
        useContext(ProductContext);

    const isBiggerLgScreen = useMediaQuery((theme) =>
        theme.breakpoints.up('lg')
    );

    const [tempFilteringChips, setTempFilteringChips] =
        useState(filteringChips);
    const debouncedFilteringChips = useDebounce(tempFilteringChips, 700);

    const [keyword, setKeyword] = useState('');
    const [isOpenCollapse, setIsOpenCollapse] = useState(!isBiggerLgScreen);

    let matchedKeywordOptions = dataFilter[keyName].filter((i) =>
        keyword.trim().length > 0 ? includesTerm(i, keyword) : i
    );

    let label = mappingLabel[keyName];

    const handleCheck = useCallback(
        ({ name, checked }) => {
            setTempFilteringChips((prevValue) => {
                let updatedValue = [...prevValue];
                if (name === 'Tất cả' && checked) {
                    updatedValue = updatedValue.filter(
                        (i) => i.key !== keyName
                    );
                    return updatedValue;
                } else {
                    let hasFilteringValue =
                        updatedValue.findIndex(
                            (i) => i.chip === name && i.key === keyName
                        ) > -1;

                    if (checked && !hasFilteringValue) {
                        updatedValue.push({
                            chip: name,
                            key: keyName,
                            onDelete: () =>
                                deleteFilter({ key: keyName, value: name }),
                        });
                    } else if (!checked) {
                        updatedValue = updatedValue.filter(
                            (i) => i.chip !== name && i.key === keyName
                        );
                    }

                    return updatedValue;
                }
            });
        },
        [deleteFilter, keyName]
    );

    useEffect(() => {
        applyFilters(keyName, debouncedFilteringChips);
    }, [applyFilters, debouncedFilteringChips, keyName]);

    return (
        <Box width={'100%'}>
            <CustomButton
                id={label + 'collapse-buttonId'}
                color="disabled"
                disableEffect
                fullWidth
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontWeight: 500,
                    fontSize: 16,
                    pl: 0.75,
                    pr: 1.25,
                    py: 0,
                }}
                endIcon={
                    <KeyboardArrowDownIcon
                        fontSize="small"
                        sx={{
                            transform: isOpenCollapse
                                ? 'rotate(-180deg)'
                                : 'none',
                            transition: 'all 0.3s ease-in-out',
                        }}
                    />
                }
                children={label}
                onClick={() => setIsOpenCollapse((v) => !v)}
            />
            <Collapse in={isOpenCollapse} timeout="auto">
                {(matchedKeywordOptions.length > 5 || keyword.trim()) && (
                    <CustomInput
                        name={label + '-search-input'}
                        id={label + '-search-inputId'}
                        color="disbaled"
                        value={keyword}
                        onChange={(value) => setKeyword(value)}
                        onKeyDown={handleKeyDown}
                        startAdornment={<SearchIcon fontSize="small" />}
                        placeholder="Tìm theo tên"
                        sx={{
                            borderRadius: '20px !important',
                            position: '-webkit-sticky',
                            // eslint-disable-next-line no-dupe-keys
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                        }}
                    />
                )}
                {keyword.trim().length === 0 && (
                    <CustomCheckbox
                        id={`${label}-checkbox-all`}
                        options={['Tất cả']}
                        formatOption={formatOption}
                        getOptionChecked={(opt) =>
                            tempFilteringChips.filter((i) => i.key === keyName)
                                .length === 0
                        }
                        getOptionIndeterminated={(opt) =>
                            tempFilteringChips.filter((i) => i.key === keyName)
                                .length > 0
                        }
                        onChange={handleCheck}
                        row={!isBiggerLgScreen}
                        FormControlProps={{ sx: { width: '100%' } }}
                        sx={{
                            width: '100%',
                            fontWeight: 600,
                            pr: 0.5,
                            mr: 1,
                            ':hover': { backgroundColor: 'disabled.lighter' },
                        }}
                        margin="none"
                    />
                )}
                <Box sx={{ maxHeight: 300, overflowY: 'scroll' }}>
                    <CustomCheckbox
                        id={`${label}-checkbox`}
                        options={matchedKeywordOptions}
                        formatOption={formatOption}
                        getOptionChecked={(opt) =>
                            tempFilteringChips.findIndex(
                                (i) => i.key === keyName && i.chip === opt
                            ) > -1
                        }
                        onChange={handleCheck}
                        row={!isBiggerLgScreen}
                        sx={{
                            width: {
                                xs: '50%',
                                sm: 290,
                                md: 260,
                                lg: 'auto',
                            },
                            pr: { xs: 0, lg: 0.5 },
                            py: 0.25,
                            mr: { xs: 0, lg: 1 },
                            ':hover': { backgroundColor: 'disabled.lighter' },
                            whiteSpace: 'normal',
                        }}
                        margin="none"
                    />
                </Box>
            </Collapse>
        </Box>
    );
}

function Header({ onCloseDrawer = () => {}, ...props }) {
    const isBiggerLgScreen = useMediaQuery((theme) =>
        theme.breakpoints.up('lg')
    );

    if (isBiggerLgScreen) {
        return (
            <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{
                    position: '-webkit-sticky',
                    // eslint-disable-next-line no-dupe-keys
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                    px: 2,
                    py: 1,
                    backgroundColor: 'white',
                    borderBottomWidth: '0.5px',
                    borderBottomStyle: 'solid',
                    borderBottomColor: 'disabled.light',
                }}>
                <FilterListOutlinedIcon fontSize="small" />
                <Typography
                    component="h2"
                    sx={{ fontSize: 16, fontWeight: 600 }}>
                    Bộ lọc nâng cao
                </Typography>
            </Stack>
        );
    }
    return (
        <Box
            sx={{
                width: '100vw',
                backgroundColor: 'rgba(0,0,0,0.04)',
                px: 1,
                py: 2,
            }}>
            <Stack direction="row" width="100%" alignItems="center">
                <Typography
                    flexGrow={1}
                    component="h2"
                    sx={{
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 600,
                    }}>
                    Bộ lọc nâng cao
                </Typography>
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <CustomButton
                        color="disabled"
                        disableEffect
                        useIconButton
                        onClick={onCloseDrawer}
                        children={<ClearIcon />}
                    />
                </Box>
            </Stack>
        </Box>
    );
}

export default function FilterBox({ ...props }) {
    const {
        dataFilter,
        isDrawerOpen,
        setIsDrawerOpen,
        totalProducts,
        resetAllFilters,
        filteringChips,
    } = useContext(ProductContext);
    const isBiggerLgScreen = useMediaQuery((theme) =>
        theme.breakpoints.up('lg')
    );

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false);
    };

    const items = Object.keys(dataFilter).filter(
        (keyName) => dataFilter[keyName].length > 0
    );

    if (totalProducts === 0 && filteringChips.length === 0) {
        return null;
    } else if (isBiggerLgScreen) {
        return (
            <div>
                <Box
                    sx={{
                        position: '-webkit-sticky',
                        // eslint-disable-next-line no-dupe-keys
                        position: 'sticky',
                        top: 10,
                        maxHeight: '97vh',
                        borderRadius: '10px',
                        overflow: 'scroll',
                    }}>
                    <Stack
                        width={{ xs: '100%', lg: '250px' }}
                        height={{ xs: '100%', lg: 'auto' }}
                        sx={{
                            backgroundColor: 'white',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: 'disabled.lighter',
                        }}>
                        <Header />
                        <CustomList
                            id="page-product-filter-listId"
                            density="comfortable"
                            items={items.map((keyName, idx, arr) => ({
                                name: mappingLabel[keyName] + idx,
                                subLabel: null,
                                label: <CustomListItem keyName={keyName} />,
                                divider: idx !== arr.length - 1,
                            }))}
                        />
                    </Stack>
                </Box>
            </div>
        );
    } else {
        return (
            <div>
                <Drawer
                    sx={{
                        '&.MuiDrawer-root .MuiPaper-root ': {
                            height: { xs: '100vh', sm: '90vh', md: '80vh' },
                        },
                    }}
                    open={isDrawerOpen}
                    variant="temporary"
                    anchor="bottom"
                    ModalProps={{ keepMounted: true }}
                    onClose={handleCloseDrawer}>
                    <Stack
                        sx={{
                            width: { xs: '100vw', lg: '250px' },
                            height: { xs: '100%', lg: 'auto' },
                            backgroundColor: 'white',
                        }}>
                        <Header onCloseDrawer={handleCloseDrawer} />
                        <Box
                            flexGrow={1}
                            sx={{
                                width: '100vw',
                                overflow: 'scroll',
                            }}>
                            <CustomList
                                id="page-product-filter-listId"
                                density="comfortable"
                                items={items.map((keyName, idx) => ({
                                    name: mappingLabel[keyName] + idx,
                                    subLabel: null,
                                    label: <CustomListItem keyName={keyName} />,
                                    divider: true,
                                }))}
                                ListProps={{
                                    sx: {
                                        py: 0,
                                        width: '100%',
                                        maxHeight: 'calc(100% - 210px)',
                                    },
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                borderTopColor: 'disabled.lighter',
                                borderTopWidth: '1px',
                                borderTopStyle: 'solid',
                                width: '100vw',
                                backgroundColor: 'disaled.lighter',
                            }}>
                            <Box
                                sx={{
                                    maxHeight: 137,
                                    overflow: 'scroll',
                                    backgroundColor: 'rgba(0,0,0,0.04)',
                                }}>
                                <ActiveFilterBox
                                    chips={filteringChips}
                                    onClickResetButton={resetAllFilters}
                                />
                            </Box>
                        </Box>
                    </Stack>
                </Drawer>
            </div>
        );
    }
}

function replaceVietnameseCharacters(inputString) {
    // Define a mapping of Vietnamese characters to normal characters
    const charMap = {
        á: 'a',
        à: 'a',
        ả: 'a',
        ã: 'a',
        ạ: 'a',
        ă: 'a',
        ắ: 'a',
        ằ: 'a',
        ẳ: 'a',
        ẵ: 'a',
        ặ: 'a',
        â: 'a',
        ấ: 'a',
        ầ: 'a',
        ẩ: 'a',
        ẫ: 'a',
        ậ: 'a',
        é: 'e',
        è: 'e',
        ẻ: 'e',
        ẽ: 'e',
        ẹ: 'e',
        ê: 'e',
        ế: 'e',
        ề: 'e',
        ể: 'e',
        ễ: 'e',
        ệ: 'e',
        í: 'i',
        ì: 'i',
        ỉ: 'i',
        ĩ: 'i',
        ị: 'i',
        ó: 'o',
        ò: 'o',
        ỏ: 'o',
        õ: 'o',
        ọ: 'o',
        ô: 'o',
        ố: 'o',
        ồ: 'o',
        ổ: 'o',
        ỗ: 'o',
        ộ: 'o',
        ơ: 'o',
        ớ: 'o',
        ờ: 'o',
        ở: 'o',
        ỡ: 'o',
        ợ: 'o',
        ú: 'u',
        ù: 'u',
        ủ: 'u',
        ũ: 'u',
        ụ: 'u',
        ư: 'u',
        ứ: 'u',
        ừ: 'u',
        ử: 'u',
        ữ: 'u',
        ự: 'u',
        ý: 'y',
        ỳ: 'y',
        ỷ: 'y',
        ỹ: 'y',
        ỵ: 'y',
        Á: 'A',
        À: 'A',
        Ả: 'A',
        Ã: 'A',
        Ạ: 'A',
        Ă: 'A',
        Ắ: 'A',
        Ằ: 'A',
        Ẳ: 'A',
        Ẵ: 'A',
        Ặ: 'A',
        Â: 'A',
        Ấ: 'A',
        Ầ: 'A',
        Ẩ: 'A',
        Ẫ: 'A',
        Ậ: 'A',
        É: 'E',
        È: 'E',
        Ẻ: 'E',
        Ẽ: 'E',
        Ẹ: 'E',
        Ê: 'E',
        Ế: 'E',
        Ề: 'E',
        Ể: 'E',
        Ễ: 'E',
        Ệ: 'E',
        Í: 'I',
        Ì: 'I',
        Ỉ: 'I',
        Ĩ: 'I',
        Ị: 'I',
        Ó: 'O',
        Ò: 'O',
        Ỏ: 'O',
        Õ: 'O',
        Ọ: 'O',
        Ô: 'O',
        Ố: 'O',
        Ồ: 'O',
        Ổ: 'O',
        Ỗ: 'O',
        Ộ: 'O',
        Ơ: 'O',
        Ớ: 'O',
        Ờ: 'O',
        Ở: 'O',
        Ỡ: 'O',
        Ợ: 'O',
        Ú: 'U',
        Ù: 'U',
        Ủ: 'U',
        Ũ: 'U',
        Ụ: 'U',
        Ư: 'U',
        Ứ: 'U',
        Ừ: 'U',
        Ử: 'U',
        Ữ: 'U',
        Ự: 'U',
        Ý: 'Y',
        Ỳ: 'Y',
        Ỷ: 'Y',
        Ỹ: 'Y',
        Ỵ: 'Y',
        Đ: 'D',
        đ: 'd',
    };

    // Use the replace method with a callback function
    const result = inputString.replace(
        /[^A-Za-z0-9]/g,
        (match) => charMap[match] || match
    );
    return result;
}

function includesTerm(term, comparedString) {
    let replacedCharacterTerm = replaceVietnameseCharacters(term).toLowerCase();
    let replacedCharacterComparedString = replaceVietnameseCharacters(
        comparedString.trim()
    ).toLowerCase();

    return replacedCharacterTerm.includes(replacedCharacterComparedString)
        ? term
        : null;
}

function handleKeyDown(event) {
    const forbiddenKeys = [
        '!',
        '@',
        '#',
        '$',
        '%',
        '^',
        '&',
        '*',
        '(',
        ')',
        '-',
        '_',
        '=',
        '+',
        '[',
        ']',
        '{',
        '}',
        '\\',
        '|',
        ';',
        ':',
        // "'",
        '"',
        ',',
        '.',
        '<',
        '>',
        '/',
        '?',
        '`',
        '~',
    ];

    if (forbiddenKeys.includes(event.key)) {
        event.preventDefault();
    }
}

const formatOption = (opt) => ({
    name: opt,
    label: (
        <Typography
            variant="caption"
            sx={{
                fontSize: { xs: 14, sm: 15 },
                lineHeight: 1.2,
                fontWeight: 'inherit',
            }}>
            {opt}
        </Typography>
    ),
});

const mappingLabel = {
    prodCate: 'Nhóm sản phẩm',
    manuName: 'Nhà sản xuất',
    manuNation: 'Nước sản xuất',
    prescription: 'Loại sản phẩm',
    administration: 'Đường dùng',
    dosageForm: 'Dạng bào chế',
};
