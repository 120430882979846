import { Stack, Typography } from '@mui/material';
import { ReadMoreRounded as ReadMoreRoundedIcon } from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

import { CustomButton, CustomAvatar } from 'UI';

import articleLv1Json from 'data/articleLv1.json';

export default function NoFoundData({
    type = 'product',
    isFiltering = false,
    onClearFilters = () => {},
    subCategoryData = null,
    ...props
}) {
    const navigate = useNavigate();

    if (type === 'product') {
        return (
            <Stack
                spacing={2}
                justifyContent="center"
                alignItems="center"
                px={2}
                py={{ xs: 2, sm: 4 }}>
                <CustomAvatar
                    src="../assets/images/pharmacy-illustration.png"
                    sx={{
                        width: '100%',
                        maxWidth: 500,
                        opacity: 0.5,
                        height: 200,
                    }}
                />
                <Stack pt={2} spacing={0.5}>
                    <Typography
                        sx={{
                            fontSize: 26,
                            fontWeight: 600,
                            textAlign: 'center',
                        }}>
                        {isFiltering
                            ? 'Không tìm thấy kết quả nào phù hợp với điều kiện lọc'
                            : 'Chưa có sản phẩm nào trong nhóm này'}
                    </Typography>
                    <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                        {isFiltering
                            ? 'Hãy thử lại bằng cách thay đổi điều kiện lọc hoặc'
                            : 'Dữ liệu đang cập nhật, bạn hãy quay lại sau nhé...'}
                    </Typography>
                </Stack>
                {isFiltering && (
                    <CustomButton
                        id="clear-all-filter-custom-button"
                        variant="contained"
                        onClick={onClearFilters}
                        sx={{
                            width: 'fit-content',
                            borderRadius: 5,
                            px: 2,
                            py: 1,
                        }}
                        children={
                            <Typography variant="h6" sx={{ fontSize: 18 }}>
                                Xóa tất cả bộ lọc
                            </Typography>
                        }
                    />
                )}
            </Stack>
        );
    } else if (type === 'search') {
        return (
            <Stack
                spacing={2}
                justifyContent="center"
                alignItems="center"
                px={2}
                py={{ xs: 2, sm: 4 }}>
                <CustomAvatar
                    src="../assets/images/pharmacy-illustration.png"
                    sx={{
                        width: '100%',
                        maxWidth: 500,
                        opacity: 0.5,
                        height: 200,
                    }}
                />
                <Stack pt={2} spacing={0.5}>
                    <Typography
                        sx={{
                            fontSize: 26,
                            fontWeight: 600,
                            textAlign: 'center',
                        }}>
                        {isFiltering
                            ? 'Không tìm thấy kết quả nào phù hợp với điều kiện lọc'
                            : 'Chưa có kết quả nào trong nhóm này'}
                    </Typography>
                    <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                        {isFiltering
                            ? 'Hãy thử lại bằng cách thay đổi điều kiện lọc hoặc'
                            : 'Dữ liệu đang cập nhật, bạn hãy quay lại sau nhé...'}
                    </Typography>
                </Stack>
                {isFiltering && (
                    <CustomButton
                        id="clear-all-filter-custom-button"
                        variant="contained"
                        onClick={onClearFilters}
                        sx={{
                            width: 'fit-content',
                            borderRadius: '20px',
                            px: 2,
                            py: 1,
                        }}
                        children={
                            <Typography variant="h6" sx={{ fontSize: 18 }}>
                                Xóa tất cả bộ lọc
                            </Typography>
                        }
                    />
                )}
            </Stack>
        );
    } else if (type === 'article') {
        
        let slug =
            '/bai-viet-suc-khoe' +
            (articleLv1Json.find((i) => i.name === subCategoryData?.parentName)
                ?.slug ?? '');

        return (
            <Stack
                spacing={2}
                justifyContent="center"
                alignItems="center"
                px={2}
                py={{ xs: 2, sm: 4 }}>
                <CustomAvatar
                    src="../assets/images/pharmacy-illustration.png"
                    sx={{
                        width: '100%',
                        maxWidth: 500,
                        opacity: 0.5,
                        height: 200,
                    }}
                />
                <Stack py={2} spacing={0.5}>
                    <Typography
                        sx={{
                            fontSize: 26,
                            fontWeight: 600,
                            textAlign: 'center',
                        }}>
                        Chưa có bài viết nào trong chủ đề này
                    </Typography>
                    <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                        Dữ liệu đang cập nhật, bạn hãy quay lại sau nhé...
                    </Typography>
                </Stack>
                <CustomButton
                    id="clear-all-filter-custom-button"
                    variant="contained"
                    onClick={() => navigate(slug)}
                    sx={{
                        width: 'fit-content',
                        borderRadius: 8,
                        px: 4,
                        py: 2,
                        color: 'white',
                        ':hover': { color: 'white' },
                        '&:focus': {
                            color: 'white',
                        },
                        '&:active': {
                            color: 'text.primary',
                        },
                        alignItems: 'flex-start',
                    }}
                    startIcon={
                        <ReadMoreRoundedIcon sx={{ width: 29, height: 29 }} />
                    }
                    children={
                        <Typography
                            variant="h6"
                            sx={{
                                color: 'inherit',
                                fontSize: 18,
                                lineHeight: 1.5,
                            }}>
                            <span>Xem thêm các bài viết chủ đề</span>
                            <br />
                            <i>{subCategoryData?.parentName}</i>
                        </Typography>
                    }
                />
            </Stack>
        );
    } else return null;
}
