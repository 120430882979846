import { Typography, Stack } from '@mui/material';

import { CustomButton } from 'UI';

export default function EndOfListWithClearFilter({
    isFiltering = false,
    onClearFilters = () => {},
    ...props
}) {
    return (
        <Stack spacing={1} alignItems="center" justifyContent="center">
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    textAlign: 'center',
                }}>
                Bạn đã xem hết danh sách
            </Typography>
            {isFiltering && (
                <CustomButton
                    id="clear-filters-custom-button"
                    variant="contained"
                    onClick={onClearFilters}
                    sx={{
                        width: 'fit-content',
                        borderRadius: 5,
                    }}
                    children={
                        <Typography variant="h6" sx={{ fontSize: 16 }}>
                            Xóa tất cả bộ lọc
                        </Typography>
                    }
                />
            )}
        </Stack>
    );
}
