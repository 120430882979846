import { Stack, Box, Typography } from '@mui/material';
import { Plagiarism as PlagiarismIcon } from '@mui/icons-material';

import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getSimpleQuery } from 'utils/http.js';

import { CustomMasonry } from 'UI';

import ProductCard from './ProductCard';

export default function RecentWatchProd({ ...otherProps }) {
    const recentWatchProd = useSelector((state) => state.recentWatchProd);

    const { data } = useQuery({
        queryKey: ['product-item', { recentWatchProd }],
        queryFn: ({ signal }) =>
            getSimpleQuery({
                signal,
                apiUrl: `/ecom/product-item/data?sku=${recentWatchProd.join(
                    ','
                )}&type=1&skip=0&take=20`,
            }),
        staleTime: 30000,
        retry: 0,
        enabled: recentWatchProd.length > 0,
    });

    if (recentWatchProd.length === 0 || !(data && data.data.length > 0)) {
        return null;
    }

    return (
        <Box
            sx={{
                px: { xs: 2, sm: 2 },
                pt: { xs: 3, sm: 0 },
                pb: 2,
            }}>
            <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ pl: { xs: 1, sm: 2 }, pb: 1 }}>
                <PlagiarismIcon
                    sx={{
                        width: { xs: 28, lg: 30 },
                        height: { xs: 28, lg: 30 },
                        color: 'primary.main',
                    }}
                />
                <Typography
                    variant="h2"
                    sx={{
                        color: 'primary.main',
                        fontSize: { xs: 20, lg: 22 },
                        fontWeight: 600,
                        lineHeight: { xs: 1.4, sm: 1.5 },
                    }}>
                    Sản phẩm vừa xem
                </Typography>
            </Stack>
            <CustomMasonry
                spacing={{ xs: 1, sm: 2 }}
                elevation={1}
                id="recent-watch-masonry-id"
                columns={{ xs: 2, sm: 3, md: 4, xl: 6 }}
                items={data.data
                    .sort(
                        (a, b) =>
                            recentWatchProd.indexOf(a.sku) -
                            recentWatchProd.indexOf(b.sku)
                    )
                    .map((prodData) => {
                        return {
                            id: prodData._id,
                            content: <ProductCard prodData={prodData} />,
                        };
                    })}
                wrap={false}
            />
        </Box>
    );
}
