import { useState } from 'react';

import {
    Stack,
    Grid2,
    Typography,
    useMediaQuery,
    ListItemText,
    Box,
    ListItemButton,
    Divider,
    Collapse,
} from '@mui/material';
import {
    ExpandLess as ExpandLessIcon,
    KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
} from '@mui/icons-material';

import { lighten } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';

import breadcrumbNameMapping from 'data/breadcrumbNameMapping.json';
import { POLICIES } from 'shop/policy/PagePolicy.jsx';

import { CustomAvatar, CustomButton, CustomList } from 'UI';

import LogoBrand from '../LogoBrand.jsx';
import { contactItems } from './SectionDrawer.jsx';

const CustomSubHeader = ({ title = '', ...props }) => (
    <Typography
        sx={{
            color: 'text.secondary',
            pl: 0.5,
            fontSize: { xs: 14, md: 16 },
            fontWeight: { xs: 400, md: 500 },
            lineHeight: 1.5,
        }}
        gutterBottom>
        {title}
    </Typography>
);
const Certification = ({ ...props }) => (
    <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-evenly"
        width="100%"
        spacing={1}>
        <img
            src="../assets/images/bct.png"
            alt="bct"
            loading="lazy"
            width={150}
        />
        <a
            href="//www.dmca.com/Protection/Status.aspx?ID=ef027488-1508-4453-af8e-9c51203e026b&refurl=https://www.nhathuockhanhtrang.com.vn/"
            title="DMCA.com Protection Status"
            className="dmca-badge">
            <img
                src="https://images.dmca.com/Badges/dmca-badge-w100-2x1-03.png?ID=ef027488-1508-4453-af8e-9c51203e026b"
                alt="DMCA.com Protection Status"
                height={40}
                width={100}
            />
        </a>
    </Stack>
);

const ListOptionButtonLabel = ({
    isOpen = false,
    setIsOpen = () => {},
    primaryText = '',
    ...props
}) => {
    return (
        <CustomButton
            id={`${primaryText}-button-id`}
            disableEffect
            sx={{
                p: 0,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
            onClick={setIsOpen}>
            <ListItemText
                primary={primaryText}
                primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: isOpen ? 'primary.main' : 'text.secondary',
                    fontSize: 16,
                    textAlign: 'left',
                    textTransform: 'uppercase',
                }}
            />
            <ExpandLessIcon
                sx={(theme) => ({
                    color: isOpen ? 'primary.main' : 'text.secondary',
                    transform: isOpen ? 'rotate(180deg)' : 'rotate(90deg)',
                    borderRadius: '50%',
                    backgroundColor: isOpen
                        ? lighten(theme.palette.primary.lighter, 0.6)
                        : 'whitesmoke',
                    p: 0.25,
                    transition: 'all 0.3s ease-in-out',
                })}
            />
        </CustomButton>
    );
};
function MobileFooter({ ...props }) {
    const navigate = useNavigate();

    const [isOpenCollapse, setIsOpenCollapse] = useState({
        contact: true,
        cate: false,
        about: false,
        support: false,
    });

    let toggleCollapse = (name) =>
        setIsOpenCollapse((prev) => {
            let updatedValue = { ...prev };
            updatedValue[name] = !updatedValue[name];
            return updatedValue;
        });

    const [isExpand, setIsExpand] = useState(false);

    let listItemButtonProps = {
        component: 'button',
        disableRipple: true,
        disableTouchRipple: true,
        sx: {
            fontSize: 14,
            px: 0,
            py: 0.5,
            ':hover': {
                color: 'text.link',
                backgroundColor: 'transparent',
            },
        },
    };
    let subLabelListProps = {
        alignItems: 'center',
        density: 'compact',
        ListProps: {
            sx: {
                mt: 1,
                mb: 2,
                py: 0.5,
                backgroundColor: 'aliceblue',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'info.main',
                borderRadius: 2,
            },
        },
    };

    return (
        <>
            <Box
                sx={{
                    backgroundImage: `url('../assets/images/khanhtranglogo-02.png')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'auto 200px',
                    backgroundPosition: { xs: '5% 50%', sm: '10% 50%' },
                    ':hover': {
                        backgroundColor: 'rgba(226,237,255)',
                    },
                    border: 'none',
                    backgroundColor: 'rgba(226,237,255)',
                    width: '100%',
                    height: '140px',
                    position: 'relative',
                }}>
                <Stack
                    alignItems="center"
                    sx={{
                        position: 'absolute',
                        right: { xs: '5%', sm: '15%' },
                        top: '25%',
                        width: 'fit-content',
                        ml: 'auto',
                    }}>
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 500,
                            textAlign: 'center',
                            color: 'text.primary',
                            lineHeight: 1.4,
                        }}>
                        {'Hotline Zalo hỗ trợ'}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: 300,
                            textAlign: 'center',
                            color: 'text.secondary',
                        }}>
                        {'(08:00 - 21:30)'}
                    </Typography>
                    <Typography
                        component="a"
                        href="tel:0704430919"
                        sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            pt: 0.5,
                            color: 'text.link',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                color: 'primary.main',
                                textDecoration: 'underline',
                            },
                            textDecoration: 'none',
                        }}>
                        070.443.0919
                    </Typography>

                    <CustomButton
                        id="see-less-footer-button-id"
                        disableEffect
                        onClick={() => setIsExpand(false)}
                        sx={{
                            transform: isExpand
                                ? 'scale(1)'
                                : 'scale(0) translateY(100px)',
                            transition: 'all 0.3s ease-in-out',
                        }}
                        endIcon={<ExpandLessIcon />}>
                        Thu gọn
                    </CustomButton>
                </Stack>
            </Box>
            <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{
                    transform: isExpand ? 'scale(0)' : 'scale(1)',
                    transition: 'all 0.3s ease-in-out',
                    height: isExpand ? 0 : 'auto',
                    px: 1,
                    py: 2,
                    width: '100%',
                    backgroundColor: lighten('rgba(176,211,255)', 0.4),
                }}
                divider={
                    <Divider
                        orientation="vertical"
                        flexItem
                        variant="middle"
                        sx={{ backgroundColor: 'grey.400' }}
                    />
                }>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ flex: 1.5, px: 0.5 }}
                    spacing={0.5}>
                    <Typography
                        sx={{
                            color: 'text.secondary',
                            fontSize: 14,
                            textAlign: 'center',
                        }}>
                        Kết nối với chúng tôi
                    </Typography>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center">
                        <CustomButton
                            useIconButton
                            disableEffect
                            aria-label="facebook-icon"
                            component="a"
                            href="https://www.facebook.com/nhathuockhanhtrang"
                            children={
                                <CustomAvatar
                                    src="../assets/images/facebook-icon.png"
                                    avatarSize={27}
                                    alt="facebook-icon"
                                    sx={{ backgroundColor: 'white' }}
                                />
                            }
                        />
                        <CustomButton
                            useIconButton
                            disableEffect
                            aria-label="youtube-icon"
                            component="a"
                            href="https://www.youtube.com/@duocsitantam124/about"
                            children={
                                <CustomAvatar
                                    src="../assets/images/youtube-icon.png"
                                    avatarSize={27}
                                    alt="youtube-icon"
                                />
                            }
                        />
                        <CustomButton
                            useIconButton
                            disableEffect
                            aria-label="zalo-icon"
                            component="a"
                            href="https://zalo.me/840599482837287192"
                            children={
                                <CustomAvatar
                                    src="../assets/images/zalo-icon.png"
                                    avatarSize={27}
                                    alt="zalo-icon"
                                />
                            }
                        />
                    </Stack>
                </Stack>
                <Stack
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={0.5}
                    sx={{ px: 1, flex: 1.25 }}>
                    <Typography
                        sx={{
                            color: 'text.secondary',
                            fontSize: 14,
                            textAlign: 'center',
                            maxWidth: { xs: 120, sm: 'unset' },
                        }}>
                        Tích điểm, đổi quà, ưu đãi
                    </Typography>
                    <CustomButton
                        id="loyalty-navigation-button-id"
                        onClick={() => navigate('/khach-hang-than-thiet')}
                        disableEffect
                        sx={{
                            p: 0,
                            color: 'text.link',
                            ':hover': {
                                color: 'primary.main',
                            },
                        }}>
                        <Typography
                            sx={{
                                color: 'inherit',
                                fontSize: 14,
                            }}>
                            Xem chi tiết
                        </Typography>
                    </CustomButton>
                </Stack>

                <CustomButton
                    id="see-more-footer-button-id"
                    onClick={() => setIsExpand(true)}
                    disableEffect
                    sx={{
                        py: 0,
                        px: 1,
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                    }}
                    endIcon={
                        <KeyboardDoubleArrowDownIcon
                            sx={{ color: 'text.secondary' }}
                        />
                    }>
                    <Typography
                        sx={{
                            color: 'text.secondary',
                            fontSize: 14,
                            textAlign: 'center',
                            maxWidth: { xs: 80, sm: 'unset' },
                        }}>
                        Xem thêm thông tin
                    </Typography>
                </CustomButton>
            </Stack>
            <Collapse in={isExpand} timeout="auto" sx={{ mt: '-30px' }}>
                <CustomList
                    id="section-footer-listId"
                    alignItems="center"
                    disableGutters
                    ListProps={{ sx: { backgroundColor: 'disabled.lighter' } }}
                    ListItemProps={{
                        sx: {
                            width: 'calc(100% - 48px)',
                            '&.MuiListItem-root': {
                                py: 1,
                                mx: 'auto',
                            },
                        },
                    }}
                    items={[
                        {
                            name: 'contact',
                            label: (
                                <ListOptionButtonLabel
                                    isOpen={isOpenCollapse.contact}
                                    setIsOpen={() => toggleCollapse('contact')}
                                    primaryText="LIÊN HỆ"
                                />
                            ),
                            subLabel: (
                                <Collapse
                                    in={isOpenCollapse.contact}
                                    timeout="auto">
                                    <CustomList
                                        id="contact-listId"
                                        {...subLabelListProps}
                                        items={contactItems.filter(
                                            (i) =>
                                                i.name !== 'phone-contact' &&
                                                i.name !==
                                                    'social-media-contact'
                                        )}
                                    />
                                </Collapse>
                            ),
                            divider: true,
                        },
                        {
                            name: 'cate',
                            label: (
                                <ListOptionButtonLabel
                                    isOpen={isOpenCollapse.cate}
                                    setIsOpen={() => toggleCollapse('cate')}
                                    primaryText="DANH MỤC"
                                />
                            ),
                            subLabel: (
                                <Collapse
                                    in={isOpenCollapse.cate}
                                    timeout="auto">
                                    <CustomList
                                        id="cate-listId"
                                        {...subLabelListProps}
                                        items={[
                                            '/thuoc',
                                            '/thuc-pham-chuc-nang',
                                            '/san-pham-khac',
                                            '/bai-viet-suc-khoe',
                                        ].map((pathname) => {
                                            return {
                                                name: pathname,
                                                subLabel: null,
                                                label: (
                                                    <ListItemButton
                                                        onClick={() =>
                                                            navigate(pathname)
                                                        }
                                                        {...listItemButtonProps}>
                                                        {
                                                            breadcrumbNameMapping[
                                                                pathname
                                                            ]
                                                        }
                                                    </ListItemButton>
                                                ),
                                            };
                                        })}
                                    />
                                </Collapse>
                            ),
                            divider: true,
                        },
                        {
                            name: 'about',
                            label: (
                                <ListOptionButtonLabel
                                    isOpen={isOpenCollapse.about}
                                    setIsOpen={() => toggleCollapse('about')}
                                    primaryText="VỀ CHÚNG TÔI"
                                />
                            ),
                            subLabel: (
                                <Collapse
                                    in={isOpenCollapse.about}
                                    timeout="auto">
                                    <CustomList
                                        id="about-listId"
                                        {...subLabelListProps}
                                        items={POLICIES.filter((i) =>
                                            [
                                                'businessOperation',
                                                'security',
                                                'data',
                                            ].includes(i.code)
                                        ).map(({ pathname }) => {
                                            let policyName =
                                                breadcrumbNameMapping[pathname];

                                            return {
                                                name: pathname,
                                                subLabel: null,
                                                label: (
                                                    <ListItemButton
                                                        onClick={() =>
                                                            navigate(pathname)
                                                        }
                                                        {...listItemButtonProps}>
                                                        {policyName}
                                                    </ListItemButton>
                                                ),
                                            };
                                        })}
                                    />
                                </Collapse>
                            ),
                            divider: true,
                        },
                        {
                            name: 'support',
                            label: (
                                <ListOptionButtonLabel
                                    isOpen={isOpenCollapse.support}
                                    setIsOpen={() => toggleCollapse('support')}
                                    primaryText="HỖ TRỢ KHÁCH HÀNG"
                                />
                            ),
                            subLabel: (
                                <Collapse
                                    in={isOpenCollapse.support}
                                    timeout="auto">
                                    <CustomList
                                        id="support-listId"
                                        {...subLabelListProps}
                                        items={POLICIES.filter((i) =>
                                            [
                                                'loyalty',
                                                'deliver',
                                                'payment',
                                                'goodsReturn',
                                            ].includes(i.code)
                                        ).map(({ pathname }) => {
                                            let policyName =
                                                breadcrumbNameMapping[pathname];

                                            return {
                                                name: pathname,
                                                subLabel: null,
                                                label: (
                                                    <ListItemButton
                                                        onClick={() =>
                                                            navigate(pathname)
                                                        }
                                                        {...listItemButtonProps}>
                                                        {policyName}
                                                    </ListItemButton>
                                                ),
                                            };
                                        })}
                                    />
                                </Collapse>
                            ),
                            divider: true,
                        },
                        {
                            name: 'social',
                            label: (
                                <ListItemText
                                    primary="Kết nối với chúng tôi"
                                    primaryTypographyProps={{
                                        color: 'text.secondary',
                                        fontSize: 16,
                                        fontWeight: 400,
                                        textAlign: 'left',
                                        textTransform: 'initial',
                                    }}
                                    sx={{ p: 0, height: '100%' }}
                                />
                            ),
                            subLabel: (
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    spacing={1}>
                                    <CustomButton
                                        useIconButton
                                        disableEffect
                                        aria-label="facebook-icon"
                                        component="a"
                                        href="https://www.facebook.com/nhathuockhanhtrang"
                                        children={
                                            <CustomAvatar
                                                src="../assets/images/facebook-icon.png"
                                                avatarSize={27}
                                                alt="facebook-icon"
                                                sx={{
                                                    backgroundColor: 'white',
                                                }}
                                            />
                                        }
                                    />
                                    <CustomButton
                                        useIconButton
                                        disableEffect
                                        aria-label="youtube-icon"
                                        component="a"
                                        href="https://www.youtube.com/@duocsitantam124/about"
                                        children={
                                            <CustomAvatar
                                                src="../assets/images/youtube-icon.png"
                                                avatarSize={27}
                                                alt="youtube-icon"
                                            />
                                        }
                                    />
                                    <CustomButton
                                        useIconButton
                                        disableEffect
                                        aria-label="zalo-icon"
                                        component="a"
                                        href="https://zalo.me/840599482837287192"
                                        children={
                                            <CustomAvatar
                                                src="../assets/images/zalo-icon.png"
                                                avatarSize={27}
                                                alt="zalo-icon"
                                            />
                                        }
                                    />
                                </Stack>
                            ),
                            divider: true,
                        },
                        {
                            name: 'certification',
                            label: (
                                <ListItemText
                                    primary="Chứng nhận bởi"
                                    primaryTypographyProps={{
                                        color: 'text.secondary',
                                        fontSize: 16,
                                        fontWeight: 400,
                                        textAlign: 'left',
                                        textTransform: 'initial',
                                    }}
                                    sx={{ p: 0, height: '100%' }}
                                />
                            ),
                            subLabel: (
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    width="100%"
                                    spacing={2}>
                                    <img
                                        src="../assets/images/bct.png"
                                        alt="bct"
                                        loading="lazy"
                                        width={100}
                                    />
                                    <a
                                        href="//www.dmca.com/Protection/Status.aspx?ID=ef027488-1508-4453-af8e-9c51203e026b&refurl=https://www.nhathuockhanhtrang.com.vn/"
                                        title="DMCA.com Protection Status"
                                        className="dmca-badge">
                                        <img
                                            src="https://images.dmca.com/Badges/dmca-badge-w100-2x1-03.png?ID=ef027488-1508-4453-af8e-9c51203e026b"
                                            alt="DMCA.com Protection Status"
                                            height={30}
                                            width={80}
                                        />
                                    </a>
                                </Stack>
                            ),
                        },
                    ]}
                />
            </Collapse>
        </>
    );
}

function DesktopFooter({ ...props }) {
    const navigate = useNavigate();

    let listItemButtonProps = {
        component: 'button',
        disableRipple: true,
        disableTouchRipple: true,
        sx: {
            fontSize: 14,
            px: 0,
            py: 0.5,
            color: 'text.secondary',
            ':hover': {
                color: 'text.link',
                backgroundColor: 'transparent',
            },
        },
    };

    return (
        <Grid2
            sx={{
                color: 'text.primary',
                maxWidth: 1200,
                mx: 'auto',
            }}
            container
            columnSpacing={2}
            columns={12}
            direction="row"
            justifyContent="center"
            alignItems="flex-start">
            <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
                <CustomList
                    id="contact-listId"
                    density="compact"
                    alignItems="center"
                    disableGutters={true}
                    subheader={<CustomSubHeader title="THÔNG TIN LIÊN HỆ" />}
                    items={contactItems}
                />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
                <CustomList
                    id="cateLv1-listId"
                    density="compact"
                    alignItems="center"
                    disableGutters={true}
                    subheader={<CustomSubHeader title="DANH MỤC" />}
                    items={[
                        '/thuoc',
                        '/thuc-pham-chuc-nang',
                        '/san-pham-khac',
                        '/bai-viet-suc-khoe',
                    ].map((pathname) => {
                        return {
                            name: pathname,
                            subLabel: null,
                            label: (
                                <ListItemButton
                                    onClick={() => navigate(pathname)}
                                    {...listItemButtonProps}>
                                    {breadcrumbNameMapping[pathname]}
                                </ListItemButton>
                            ),
                        };
                    })}
                />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
                <CustomList
                    id="about-listId"
                    density="compact"
                    alignItems="center"
                    disableGutters={true}
                    subheader={<CustomSubHeader title="VỀ CHÚNG TÔI" />}
                    items={POLICIES.map(({ pathname }) => {
                        let policyName = breadcrumbNameMapping[pathname];

                        return {
                            name: pathname,
                            subLabel: null,
                            label: (
                                <ListItemButton
                                    onClick={() => navigate(pathname)}
                                    {...listItemButtonProps}>
                                    {policyName}
                                </ListItemButton>
                            ),
                        };
                    })}
                />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 12, lg: 3 }}>
                <Grid2
                    container
                    columnSpacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{ pt: { xs: 2, lg: 0 } }}>
                    <Grid2 size={{ xs: 12, md: 6, lg: 12 }}>
                        <Box mb={2}>
                            <CustomSubHeader title="CHỨNG NHẬN BỞI" />
                            <Certification />
                        </Box>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6, lg: 12 }}>
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            spacing={0.5}>
                            <LogoBrand />
                            <Typography
                                component={'div'}
                                sx={{
                                    color: 'text.primary',
                                    textAlign: 'center',
                                    fontSize: 12,
                                }}>
                                <span>Copyright</span>
                                <sup>©</sup>{' '}
                                <span>Nhà thuốc Khánh Trang 2023</span>
                            </Typography>
                        </Stack>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2>
    );
}

export default function SectionFooter({ ...otherProps }) {
    const isBiggerMdScreen = useMediaQuery((theme) =>
        theme.breakpoints.up('md')
    );

    if (!isBiggerMdScreen) {
        return <MobileFooter />;
    }

    return (
        <Box
            sx={{
                pt: 3,
                pb: 3,
                px: 5,
                backgroundColor: '#f5f2e9',
            }}>
            <DesktopFooter />
        </Box>
    );
}
