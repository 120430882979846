import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

/**
 * Custom hook to wrap FontAwesome icons with MUI's Box for `sx` support.
 * @param {IconDefinition} icon - The FontAwesome icon to use.
 * @returns {JSX.Element} - A React component that accepts `sx` props.
 */
const useFaIcon = (icon) => {
  return (props) => (
    <Box component={FontAwesomeIcon} icon={icon} {...props} />
  );
};

export default useFaIcon;