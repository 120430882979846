import { useContext, useMemo } from 'react';

import { Stack, Typography, Box } from '@mui/material';

import { ProductContext } from 'context/product-context';

import { useLocation } from 'react-router-dom';

import FilterButton from 'shop/components/FilterButton';
import ViewTypeButton from 'shop/components/ViewTypeButton';
import NavigationButton from 'shop/components/NavigationButton.jsx';

import cateLv2Json from 'data/cateLv2.json';
import cateLv3Json from 'data/cateLv3.json';

export default function Header({ ...props }) {
    const {
        totalProducts,
        setIsDrawerOpen,
        viewType,
        toggleViewType,
        filteringChips,
    } = useContext(ProductContext);

    const location = useLocation().pathname;

    let menuItems = useMemo(() => {
        if (location.startsWith('/thuc-pham-chuc-nang')) {
            return [
                {
                    id: '02-1',
                    mainCategory: ' Thực phẩm chức năng',
                    isSelected: true,
                    subCategories: cateLv2Json
                        .filter((i) => i.parentName === 'Thực phẩm chức năng')
                        .map((i) => ({
                            ...i,
                            slug: '/thuc-pham-chuc-nang' + i.slug,
                            isSelected:
                                '/thuc-pham-chuc-nang' + i.slug === location,
                        })),
                },
            ];
        } else if (location.startsWith('/thuoc')) {
            return cateLv2Json
                .filter((i) => i.parentName === 'Thuốc')
                .map((i) => ({
                    id: i.id,
                    mainCategory: i.name,
                    isSelected: '/thuoc' + i.slug === location,
                    subCategories: cateLv3Json
                        .filter((x) => x.parentName === i.name)
                        .map((x) => ({
                            ...x,
                            slug: '/thuoc' + x.slug,
                            isSelected: '/thuoc' + x.slug === location,
                        })),
                }));
        } else if (location.startsWith('/san-pham-khac')) {
            return cateLv2Json
                .filter((i) => i.parentName === 'Sản phẩm khác')
                .map((i) => ({
                    id: i.id,
                    mainCategory: i.name,
                    isSelected: '/san-pham-khac' + i.slug === location,
                    subCategories: cateLv3Json
                        .filter((x) => x.parentName === i.name)
                        .map((x) => ({
                            ...x,
                            slug: '/san-pham-khac' + x.slug,
                            isSelected: '/san-pham-khac' + x.slug === location,
                        })),
                }));
        } else return [];
    }, [location]);

    if (totalProducts === 0 && filteringChips.length === 0) {
        return null;
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stack
                sx={{
                    px: 2,
                    pb: { xs: 1, sm: 2 },
                    pt: { xs: 1, lg: 2 },
                    backgroundColor: 'white',
                    borderRadius:
                        filteringChips.length === 0
                            ? { xs: '0px 0px 10px 10px', lg: '10px' }
                            : { xs: '0px', lg: '10px 10px 0px 0px' },
                }}
                direction={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                justifyContent={{ xs: 'center', sm: 'space-between' }}
                spacing={1}>
                <Typography
                    component="h2"
                    sx={{
                        width: 'fit-content',
                        whiteSpace: 'nowrap',
                        fontSize: 18,
                        fontWeight: 600,
                        flexGrow: { xs: 0, md: 1 },
                    }}>
                    Danh sách sản phẩm
                </Typography>
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{ width: '100%', pb: { xs: 1, sm: 0 } }}>
                    <Box flexGrow={{ xs: 1, sm: 0 }}>
                        <NavigationButton menuItems={menuItems} />
                    </Box>
                    <FilterButton onClick={setIsDrawerOpen} />
                    <ViewTypeButton
                        value={viewType}
                        onChange={toggleViewType}
                    />
                </Stack>
            </Stack>
        </Box>
    );
}
