import { useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { MenuBook as MenuBookIcon } from '@mui/icons-material';

import { useParams, useLoaderData, useLocation } from 'react-router-dom';
import { useInfiniteQuery, keepPreviousData } from '@tanstack/react-query';
import { getSimpleQuery } from 'utils/http.js';

import { CustomHelmet, CustomMasonry } from 'UI';
import { LoadingModal } from 'template';
import { ArticleContext } from 'context/article-context.js';

import SectionBreadcrumbs from 'layout/section/SectionBreadcrumbs.jsx';
import Page404 from 'shop/404/Page404.jsx';
import ArticleCard from './ArticleCard.jsx';

import PageArticleSkeleton from 'shop/components/PageArticleSkeleton.jsx';
import NotFoundData from 'shop/components/NotFoundData.jsx';
import ViewTypeButton from 'shop/components/ViewTypeButton.jsx';
import EndOfListWithFetchMore from 'shop/components/EndOfListWithFetchMore.jsx';
import EndOfListWithClearFilter from 'shop/components/EndOfListWithClearFilter.jsx';
import NavigationButton from 'shop/components/NavigationButton.jsx';

import Header from './subComponents/Header.jsx';

import articleLv1Json from 'data/articleLv1.json';
import articleLv2Json from 'data/articleLv2.json';

const PAGE_SIZE = 12;

export default function PageArticleList({ ...otherProps }) {
    let location = useLocation();

    const { subCategorySlug } = useParams();
    const {
        mainCategoryData,
        subCategoryData,
        currentCategoryLevel,
        currentCategoryTitle,
        breadcrumbsData,
    } = useLoaderData();

    const {
        data,
        isLoading, // True only on the initial load.
        isFetching, // True when fetching new data (subsequent requests).
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: ['article-item', { subCategorySlug }],
        queryFn: ({ pageParam = 0, signal }) =>
            getSimpleQuery({
                signal,
                apiUrl: `/ecom/article-item/data?${
                    subCategorySlug ? `subCategorySlug=${subCategorySlug}&` : ''
                }type=1&skip=${pageParam}&take=${PAGE_SIZE}`,
            }),
        getNextPageParam: (lastPage, allPages) => {
            const totalFetchedItems = allPages.flatMap(
                (page) => page.data
            ).length;
            return totalFetchedItems < lastPage.totalCount
                ? totalFetchedItems
                : undefined;
        },
        placeholderData: keepPreviousData,
        staleTime: 30000,
        retry: 0,
    });

    const [viewType, setViewType] = useState('vertical');
    const toggleViewType = () =>
        setViewType((v) => (v === 'vertical' ? 'horizontal' : 'vertical'));

    if (subCategorySlug && !subCategoryData) {
        return <Page404 />;
    }

    if (isLoading) {
        return <PageArticleSkeleton />;
    }
    const mergedArticles = data?.pages?.flatMap((page) => page.data) || [];
    const totalLoadedArticles = mergedArticles.length;
    const totalArticles = parseInt(data?.pages?.[0]?.totalCount || 0);

    const contextValue = {
        mainCategoryData,
        subCategoryData,
        currentCategoryLevel,
        currentCategoryTitle,
        totalArticles,
        viewType,
        toggleViewType,
    };

    return (
        <>
            <CustomHelmet
                title={`${
                    currentCategoryTitle === 'Bài viết sức khỏe'
                        ? currentCategoryTitle
                        : 'Các bài viết chủ đề '
                }${currentCategoryTitle.toLowerCase()} - Nhà thuốc Khánh Trang`}
                description={`Tổng hợp các bài viết của Nhà thuốc Khánh Trang${
                    currentCategoryTitle === 'Bài viết sức khỏe'
                        ? ''
                        : ' về ' + currentCategoryTitle.toLowerCase()
                }, nhằm cung cấp thông tin sức khỏe, tư vấn dinh dưỡng và cách chăm sóc, phòng tránh bệnh cho gia đình `}
                slug={`https://nhathuockhanhtrang.com.vn${location.pathname}`}
            />
            <ArticleContext.Provider value={contextValue}>
                <Stack
                    spacing={2}
                    p={{ xs: 1.5, sm: 2 }}
                    sx={{ maxWidth: 1200, mx: 'auto' }}>
                    <LoadingModal isLoading={isFetching} />
                    <Box
                        sx={{
                            pt: { xs: 1.5, sm: 2, md: 1 },
                            pb: { xs: 0.5, md: 1 },
                        }}>
                        <Box
                            mt={{ xs: 1, sm: 0 }}
                            sx={{
                                backgroundColor: 'white',
                                borderRadius: '10px',
                                py: 2,
                            }}>
                            <Box px={3}>
                                <SectionBreadcrumbs
                                    breadcrumbsData={breadcrumbsData}
                                />
                            </Box>
                            <Header />
                        </Box>
                    </Box>
                    {totalLoadedArticles === 0 ? (
                        <NotFoundData
                            type="article"
                            subCategoryData={subCategoryData}
                        />
                    ) : (
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                borderRadius: 2,
                                p: 2,
                            }}>
                            <CustomMasonry
                                spacing={2}
                                elevation={1}
                                id="page-article-list-masonryId"
                                columns={
                                    viewType === 'vertical'
                                        ? { xs: 1, sm: 2, lg: 3, xl: 4 }
                                        : { xs: 1, lg: 2 }
                                }
                                items={mergedArticles.map((articleData) => ({
                                    id: articleData._id,
                                    content: (
                                        <Box sx={{ pb: 4 }}>
                                            <ArticleCard
                                                articleData={articleData}
                                                viewType={viewType}
                                            />
                                        </Box>
                                    ),
                                }))}
                            />
                        </Box>
                    )}
                    {hasNextPage ? (
                        <EndOfListWithFetchMore
                            isFetching={isFetchingNextPage}
                            title={`Xem thêm ${Number(
                                totalArticles - totalLoadedArticles
                            ).toLocaleString()} bài viết`}
                            fetchNextPage={fetchNextPage}
                        />
                    ) : (
                        totalArticles > PAGE_SIZE && (
                            <EndOfListWithClearFilter
                                isFiltering={false}
                                onClearFilters={() => {}}
                            />
                        )
                    )}
                </Stack>
            </ArticleContext.Provider>
        </>
    );
}

export const loader = ({ params }) => {
    let { subCategorySlug } = params;
    const findSubCategory =
        [...articleLv1Json, ...articleLv2Json].find(
            (item) => item.slug === '/' + subCategorySlug
        ) || null;
    const currentCategoryLevel = Number(findSubCategory?.level ?? 0) + 1;
    let breadcrumbsData = [
        {
            to: `/bai-viet-suc-khoe`,
            name: 'Bài viết sức khỏe',
            isNavLink: currentCategoryLevel !== 1,
        },
    ];
    if (currentCategoryLevel === 2) {
        breadcrumbsData.push({
            to: `/bai-viet-suc-khoe/${subCategorySlug}`,
            name: findSubCategory.name,
            isNavLink: false,
        });
    }
    if (currentCategoryLevel === 3) {
        const parentCategory = articleLv1Json.find(
            (i) => i.name === findSubCategory.parentName
        );
        breadcrumbsData.push(
            {
                to: `/bai-viet-suc-khoe${parentCategory.slug}`,
                name: findSubCategory.parentName,
            },
            {
                to: `/bai-viet-suc-khoe/${subCategorySlug}`,
                name: findSubCategory.name,
                isNavLink: false,
            }
        );
    }
    let currentCategoryTitle = findSubCategory
        ? findSubCategory.name
        : 'Bài viết sức khỏe';
    return {
        mainCategoryData: {
            name: 'Bài viết sức khỏe',
            slug: '/bai-viet-suc-khoe',
        },
        subCategoryData: findSubCategory,
        currentCategoryLevel,
        currentCategoryTitle,
        breadcrumbsData,
    };
};
