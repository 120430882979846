import { Stack, Box, Typography } from '@mui/material';
import { Inventory2 as Inventory2Icon } from '@mui/icons-material';

import { useQuery } from '@tanstack/react-query';
import { getSimpleQuery } from 'utils/http.js';

import { CustomMasonry } from 'UI';

import ProductCard from './ProductCard';

export default function SimilarProd({ subCategorySlug = '', ...otherProps }) {
    const { data } = useQuery({
        queryKey: ['product-item', { subCategorySlug }],
        queryFn: ({ signal }) =>
            getSimpleQuery({
                signal,
                apiUrl: `/ecom/product-item/data?subCategorySlug=${subCategorySlug}&type=1&skip=0&take=20`,
            }),
        staleTime: 30000,
        retry: 0,
        enabled: !!subCategorySlug,
    });

    if (!subCategorySlug || !(data && data.data.length > 0)) {
        return null;
    }

    return (
        <Box
            sx={{
                px: { xs: 2, sm: 2 },
                pt: { xs: 3, sm: 0 },
                pb: 2,
            }}>
            <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ pl: { xs: 1, sm: 2 }, pb: 1 }}>
                <Inventory2Icon
                    sx={{
                        width: { xs: 26, lg: 28 },
                        height: { xs: 26, lg: 28 },
                        color: 'primary.main',
                    }}
                />
                <Typography
                    variant="h2"
                    sx={{
                        color: 'primary.main',
                        fontSize: { xs: 20, lg: 22 },
                        fontWeight: 600,
                        lineHeight: { xs: 1.4, sm: 1.5 },
                    }}>
                    Sản phẩm tương tự
                </Typography>
            </Stack>
            <CustomMasonry
                spacing={{ xs: 1, sm: 2 }}
                elevation={1}
                id="similar-prod-masonry-id"
                columns={{ xs: 2, sm: 3, md: 4, xl: 6 }}
                items={data.data.map((prodData) => {
                    return {
                        id: prodData._id,
                        content: <ProductCard prodData={prodData} />,
                    };
                })}
                wrap={false}
            />
        </Box>
    );
}
