import { useEffect, useMemo, useState } from 'react';

import { Box, Toolbar, useMediaQuery } from '@mui/material';

import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo } from 'store/userInfo-slice.js';

import SectionDrawer from 'layout/section/SectionDrawer.jsx';
import SectionAppbar from 'layout/section/SectionAppbar.jsx';
import SectionFooter from 'layout/section/SectionFooter.jsx';

import ShopNavbar from 'layout/navbar/ShopNavbar.jsx';
import AdminNavbar from 'layout/navbar/AdminNavbar.jsx';

import LoginDialog from 'auth/LoginDialog.jsx';
import LogoutDialog from 'auth/LogoutDialog';

import { CustomBackToTopButton } from 'UI';

let isFirstMount = true;

export default function PageLayout({
    route = 'shop',
    children,
    ...otherProps
}) {
    const isDisableBackground = useSelector(
        (state) => state.system.dimBackground
    );

    let dispatch = useDispatch();
    const location = useLocation().pathname;
    const isBiggerMdScreen = useMediaQuery(
        (theme) => theme.breakpoints.up('md') // >= 800
    );

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    let selectedNavbar = useMemo(() => {
        return location.startsWith('/admin') ? <AdminNavbar /> : <ShopNavbar />;
    }, [location]);

    const [isOpenLoginDialog, setIsOpenLoginDialog] = useState(false);
    const [isOpenLogoutDialog, setIsOpenLogoutDialog] = useState(false);

    useEffect(() => {
        if (isFirstMount) {
            isFirstMount = false;
            return;
        }
        dispatch(fetchUserInfo());
    }, [dispatch, route]);

    return (
        <Box
            component="main"
            sx={{ width: '100%', backgroundColor: '#f0f3fc' }}>
            <SectionAppbar
                openLoginDialog={() => setIsOpenLoginDialog(true)}
                openLogoutDialog={() => setIsOpenLogoutDialog(true)}
                openDrawer={() => setIsDrawerOpen(true)}
                route={route}
            />
            <SectionDrawer
                openLoginDialog={() => setIsOpenLoginDialog(true)}
                openLogoutDialog={() => setIsOpenLogoutDialog(true)}
                onClose={() => setIsDrawerOpen(false)}
                open={isDrawerOpen}
            />
            <Toolbar variant="dense" id="back-to-top-anchor" />
            <Toolbar variant="dense" sx={{ mb: { xs: 5, md: 0 } }} />
            <Box
                width="100%"
                sx={{
                    position: 'relative',
                    ...(isDisableBackground && {
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            transition: 'background-color 0.3s ease',
                            zIndex: 200,
                        },
                        overflowY: 'hidden',
                        pointerEvents: 'none',
                    }),
                }}>
                {isBiggerMdScreen && selectedNavbar}
                {children}
                <CustomBackToTopButton />
                <SectionFooter />
            </Box>
            {isOpenLoginDialog && (
                <LoginDialog
                    open={isOpenLoginDialog}
                    onClose={() => setIsOpenLoginDialog(false)}
                />
            )}
            {isOpenLogoutDialog && (
                <LogoutDialog
                    open={isOpenLogoutDialog}
                    onClose={() => setIsOpenLogoutDialog(false)}
                />
            )}
        </Box>
    );
}
