import { useContext } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { MenuBook as MenuBookIcon } from '@mui/icons-material';

import { useLocation } from 'react-router-dom';

import { ArticleContext } from 'context/article-context';

import ViewTypeButton from 'shop/components/ViewTypeButton.jsx';

import NavigationButton from 'shop/components/NavigationButton.jsx';

import articleLv1Json from 'data/articleLv1.json';
import articleLv2Json from 'data/articleLv2.json';

export default function Header({ ...props }) {
    let location = useLocation().pathname;
    const { viewType, toggleViewType } = useContext(ArticleContext);

    let menuItems = articleLv1Json.map((i) => ({
        id: i.id,
        mainCategory: i.name,
        isSelected: '/bai-viet-suc-khoe' + i.slug === location,
        subCategories: articleLv2Json
            .filter((x) => x.parentName === i.name)
            .map((x) => ({
                ...x,
                slug: '/bai-viet-suc-khoe' + x.slug,
                isSelected: '/bai-viet-suc-khoe' + x.slug === location,
            })),
    }));

    return (
        <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent={{
                xs: 'center',
                sm: 'space-between',
            }}
            alignItems={{
                xs: 'flex-start',
                sm: 'center',
            }}
            spacing={{ xs: 2, sm: 0 }}
            sx={{ px: 3, pt: 1 }}>
            <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                flexGrow={1}>
                <MenuBookIcon
                    sx={{
                        width: { xs: 26, lg: 28 },
                        height: { xs: 26, lg: 28 },
                        color: 'primary.main',
                    }}
                />
                <Typography
                    variant="h2"
                    sx={{
                        width: '100%',
                        color: 'primary.main',
                        fontSize: { xs: 20, lg: 22 },
                        fontWeight: 600,
                        lineHeight: {
                            xs: 1.4,
                            sm: 1.5,
                        },
                    }}>
                    Danh sách bài viết
                </Typography>
            </Stack>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent={{
                    xs: 'space-between',
                    sm: 'flex-end',
                }}
                spacing={1}
                sx={{
                    width: { xs: '100%', sm: 'auto' },
                }}>
                <NavigationButton menuItems={menuItems} />
                <ViewTypeButton value={viewType} onChange={toggleViewType} />
            </Stack>
        </Stack>
    );
}
