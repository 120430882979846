import { useState, useEffect, useContext } from 'react';
import {
    Stack,
    Box,
    Typography,
    useMediaQuery,
    Card,
    CardHeader,
    Divider,
    ListItemButton,
    ToggleButtonGroup,
    ToggleButton,
} from '@mui/material';
import { useTheme, darken } from '@mui/material/styles';
import {
    KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
    FormatListBulleted as FormatListBulletedIcon,
    Person as PersonIcon,
} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

import { ProductContentContext } from 'context/product-content-context.js';

import { CustomButton, CustomList } from 'UI';

import RenderContent from 'template/RenderContent.js';

function Header({
    isBiggerText = false,
    setIsBiggerText = () => {},
    ...props
}) {
    const { itemData } = useContext(ProductContentContext);
    const isBiggerMdScreen = useMediaQuery(
        (theme) => theme.breakpoints.up('md') // >= 800
    );

    let title = (
        <Typography
            component="h1"
            variant="h6"
            sx={{
                fontSize: isBiggerText
                    ? { xs: 22, lg: 24 }
                    : { xs: 18, lg: 20 },
                fontWeight: 700,
            }}>
            {itemData.shortName}
        </Typography>
    );
    let author = (
        <Stack
            spacing={0.5}
            direction="row"
            alignItems="center"
            sx={{ flexGrow: 1 }}>
            <PersonIcon
                sx={{
                    color: 'text.secondary',
                    width: 15,
                    height: 15,
                }}
            />
            <Typography
                component="span"
                sx={{
                    fontSize: {
                        xs: isBiggerText ? 16 : 14,
                        lg: isBiggerText ? 18 : 16,
                    },
                    color: 'text.secondary',
                }}>
                {itemData?.author ?? 'Nhà thuốc Khánh Trang'}
            </Typography>
        </Stack>
    );
    const fontSizeSwitch = (
        <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="flex-start">
            <Typography
                variant="body2"
                sx={{
                    color: 'text.primary',
                    fontSize: isBiggerText ? 16 : 14,
                }}>
                Kích thước chữ
            </Typography>
            <ToggleButtonGroup
                size="small"
                value={isBiggerText}
                exclusive
                onChange={() => setIsBiggerText((v) => !v)}
                aria-label="display button group"
                sx={{
                    my: 'auto',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'primary.main',
                    borderRadius: 5,
                    height: 26,
                    '& .MuiToggleButtonGroup-grouped': {
                        margin: 0.5,
                        border: 'none',
                        '&.Mui-disabled': {
                            border: 'none',
                        },
                        '&.Mui-selected': {
                            backgroundColor: 'primary.light',
                        },
                        '&:last-of-type': {
                            borderRadius: 5,
                            p: 1,
                            m: 0,
                        },
                        '&:first-of-type': {
                            borderRadius: 5,
                            p: 1,
                            m: 0,
                        },
                    },
                }}>
                <ToggleButton
                    value={false}
                    disabled={!isBiggerText}
                    aria-label="fontSize standard"
                    sx={{ my: 0, ml: 0 }}>
                    <Typography
                        variant="caption"
                        sx={{
                            color: !isBiggerText ? 'white' : 'text.primary',
                            fontSize: isBiggerText ? 14 : 11,
                            textTransform: 'initial',
                        }}>
                        Nhỏ
                    </Typography>
                </ToggleButton>
                <ToggleButton
                    value={true}
                    disabled={isBiggerText}
                    aria-label="fontSize large"
                    sx={{ my: 0, mr: 0 }}>
                    <Typography
                        variant="caption"
                        sx={{
                            fontSize: isBiggerText ? 14 : 11,
                            color: isBiggerText ? 'white' : 'text.primary',
                            textTransform: 'initial',
                        }}>
                        Lớn
                    </Typography>
                </ToggleButton>
            </ToggleButtonGroup>
        </Stack>
    );

    return (
        <div>
            {title}
            <Divider />
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ p: 1, width: '100%' }}>
                {author}
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}>
                    {fontSizeSwitch}
                </Stack>
            </Stack>
        </div>
    );
}
function Caution({ ...props }) {
    const { caution } = useContext(ProductContentContext);

    if (!caution) {
        return null;
    }

    return (
        <Box
            sx={{
                borderLeftColor: 'primary.main',
                borderLeftWidth: '4px',
                borderLeftStyle: 'solid',
                backgroundColor: 'aliceblue',
                borderRadius: '4px',
                width: '100%',
                py: 0.5,
                px: 1,
            }}>
            <Typography
                variant="caption"
                sx={{
                    color: 'text.link',
                    fontWeight: 600,
                }}>
                {caution}
            </Typography>
        </Box>
    );
}

export default function ProductDetails({ ...otherProps }) {
    const { itemData } = useContext(ProductContentContext);

    const isBiggerMdScreen = useMediaQuery((theme) =>
        theme.breakpoints.up('md')
    );

    const navigate = useNavigate();

    const [isBiggerText, setIsBiggerText] = useState(false);

    const [isShowMore, setIsShowMore] = useState(false);

    const content = JSON.parse(itemData.contentInfo);
    const keywords = itemData.keywords
        .filter((i) => i.status && i.keyName.trim().length > 0)
        .map((i) => i.keyName.trim());

    let headerContent = content
        .filter((i) => i.type === 'header1' && i.content)
        .map((i) => i.content);

    const [selectedPart, setSelectedPart] = useState(headerContent?.[0] || '');

    const scrollToTop = () => {
        const box = document.getElementById('box-content-info');
        if (box) {
            box.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    const scrollToPart = (content) => {
        const h1Elements = document.querySelectorAll('h1');
        const container = document.querySelector('#container-content');
        const headerHeight = 100;

        for (let h1 of h1Elements) {
            if (h1.textContent.toLowerCase() === content.toLowerCase()) {
                if (!isShowMore) {
                    const h1OffsetTop = h1.offsetTop; // Top position of h1 relative to the container
                    const targetScrollTop = h1OffsetTop - headerHeight;

                    container.scrollTo({
                        top: targetScrollTop,
                        behavior: 'smooth',
                    });
                } else {
                    const h1Rect = h1.getBoundingClientRect(); // Position of h1 relative to the viewport
                    const targetScrollTop =
                        window.scrollY + h1Rect.top - headerHeight - 10; // Position relative to the document

                    window.scrollTo({
                        top: targetScrollTop,
                        behavior: 'smooth',
                    });
                }
                setTimeout(() => {
                    setSelectedPart(content);
                }, 100);

                break;
            }
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const h1Elements = document.querySelectorAll('h1');
            const headerHeight = 110; // Adjust this value for your header height
            let found = false;

            for (let index = 0; index < h1Elements.length; index++) {
                const h1 = h1Elements[index];
                const nextH1 = h1Elements?.[index + 1] || null;

                const currentRect = h1.getBoundingClientRect();
                const topOffset = headerHeight; // Offset to account for the sticky header

                // Condition 1: Current h1 is at the top of the viewport
                if (
                    currentRect.top <= topOffset &&
                    currentRect.bottom > topOffset
                ) {
                    setSelectedPart(h1.textContent);
                    found = true;
                    break;
                }

                // Condition 2: Scrolling between this h1 and the next h1
                if (nextH1) {
                    const nextRect = nextH1.getBoundingClientRect();
                    if (
                        currentRect.bottom <= topOffset &&
                        nextRect.top > topOffset
                    ) {
                        setSelectedPart(h1.textContent);
                        found = true;
                        break;
                    }
                }

                // Condition 3: Final h1 element
                if (!nextH1 && currentRect.top < topOffset) {
                    setSelectedPart(h1.textContent);
                    found = true;
                    break;
                }
            }

            if (!found) {
                setSelectedPart(headerContent?.[0] || '');
            }
        };
        if (isShowMore) {
            window.addEventListener('scroll', handleScroll);
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerContent, isShowMore]);

    const isUpdatingContent = content.length === 1 && content[0].isUpdating;
    const keywordsTypography =
        keywords.length > 0 ? (
            <div>
                <span>
                    <b>Từ khóa: </b>
                </span>
                {keywords.map((word, idx) => {
                    return (
                        <Typography
                            component="a"
                            key={word + '-' + idx}
                            onClick={() => navigate('/tim-kiem?s=' + word)}
                            sx={{
                                fontSize: 14,
                                color: 'text.link',
                                ':hover': {
                                    color: 'primary.dark',
                                },
                                textDecoration: 'none',
                                cursor: 'pointer',
                            }}>
                            {word + (idx === keywords.length - 1 ? '.' : ', ')}
                        </Typography>
                    );
                })}
            </div>
        ) : null;

    if (isUpdatingContent) {
        return (
            <Stack
                sx={{
                    backgroundColor: 'white',
                    borderRadius: { xs: 0, sm: 2 },
                    py: 2,
                    px: 3,
                }}
                spacing={1}>
                <Card variant="outlined" sx={{ width: '100%', border: 'none' }}>
                    <CardHeader
                        sx={{ p: 0 }}
                        title={
                            <Header
                                isBiggerText={isBiggerText}
                                setIsBiggerText={setIsBiggerText}
                            />
                        }
                    />
                    <Typography sx={{ fontSize: isBiggerText ? 18 : 16 }}>
                        Nội dung đang được cập nhật...
                    </Typography>
                </Card>
                {keywordsTypography}
            </Stack>
        );
    }

    return (
        <Stack
            id="box-content-info"
            sx={{
                // backgroundColor: 'white',
                borderRadius: { xs: 0, sm: '8px' },
                mt: 2,
            }}
            spacing={1}>
            <Stack
                direction="row"
                alignItems="stretch"
                justifyContent="space-between"
                spacing={3}
                sx={{ mt: { xs: 0, sm: 2 } }}>
                {headerContent.length > 0 && isBiggerMdScreen && (
                    <Box sx={{ minHeight: '100%' }}>
                        <Box
                            sx={{
                                width: 250,
                                borderRadius: 2,
                                position: '-webkit-sticky',
                                // eslint-disable-next-line no-dupe-keys
                                position: 'sticky',
                                top: 10,
                                overflow: 'hidden',
                                backgroundColor: 'white',
                            }}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-start"
                                spacing={1}
                                sx={{
                                    p: 1,
                                    pl: 2,
                                    backgroundColor: 'disabled.lighter',
                                    color: 'text.primary',
                                }}>
                                <FormatListBulletedIcon
                                    sx={{ color: 'inherit' }}
                                />
                                <Typography
                                    sx={{
                                        color: 'inherit',
                                        fontSize: 14,
                                        fontWeight: 600,
                                    }}>
                                    Nội dung chính
                                </Typography>
                            </Stack>
                            <CustomList
                                id="references-content-listId"
                                density="compact"
                                items={headerContent.map((content, idx) => {
                                    let isSelected =
                                        selectedPart.toLowerCase() ===
                                        content.toLowerCase();

                                    return {
                                        name: content + idx,
                                        label: null,
                                        subLabel: (
                                            <ListItemButton
                                                sx={{
                                                    backgroundColor:
                                                        'transparent',
                                                    color: isSelected
                                                        ? 'warning.main'
                                                        : 'text.primary',
                                                    fontWeight: isSelected
                                                        ? 500
                                                        : 400,
                                                    ':hover': {
                                                        backgroundColor:
                                                            'transparent',
                                                        color: isSelected
                                                            ? 'warning.main'
                                                            : 'text.link',
                                                    },
                                                }}
                                                onClick={() =>
                                                    scrollToPart(content)
                                                }>
                                                <Typography
                                                    sx={{
                                                        m: 0,
                                                        fontWeight: 'inherit',
                                                        color: 'inherit',
                                                        fontSize: 16,
                                                    }}>
                                                    {content}
                                                </Typography>
                                            </ListItemButton>
                                        ),
                                    };
                                })}
                            />
                        </Box>
                    </Box>
                )}
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ width: '100%', position: 'relative' }}>
                        <Card
                            variant="outlined"
                            sx={{
                                width: '100%',
                                border: 'none',
                                '&.MuiCard-root': {
                                    overflow: 'unset',
                                    borderRadius: '8px 8px 0px 0px',
                                },
                                pb: 4,
                            }}>
                            <CardHeader
                                sx={{
                                    px: 3,
                                    pb: 0,
                                    borderRadius: 2,
                                    position: '-webkit-sticky',
                                    // eslint-disable-next-line no-dupe-keys
                                    position: 'sticky',
                                    top: { xs: 0, sm: 10 },
                                    overflow: 'hidden',
                                    backgroundColor: 'white',
                                    filter: {
                                        xs: 'unset',
                                        sm: 'drop-shadow(0px -20px 0px rgba(230,234,244))',
                                    },
                                    boxShadow:
                                        '0px 10px 0px 0px rgba(255,255,255,0.5)',
                                    zIndex: 1,
                                }}
                                title={
                                    <Header
                                        isBiggerText={isBiggerText}
                                        setIsBiggerText={setIsBiggerText}
                                    />
                                }
                            />
                            <Box
                                id="container-content"
                                sx={{
                                    px: { xs: 2, sm: 0 },
                                    overflow: 'hidden',
                                }}>
                                <RenderContent
                                    newContent={content}
                                    isShowMore={isShowMore}
                                    isBiggerText={isBiggerText}
                                />
                            </Box>
                        </Card>
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                pb: isShowMore ? 0.75 : 0.25,
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                boxShadow: `0px -50px 10px 0px rgba(255,255,255,${
                                    isShowMore ? 0 : 0.4
                                }), 0px -30px 10px 0px rgba(255,255,255,${
                                    isShowMore ? 0 : 0.7
                                }), 0px -10px 10px 0px white, inset 0px 10px 0px 0px white`,
                            }}>
                            <CustomButton
                                id="toggle-more-content-buttonId"
                                disableEffect
                                onClick={() => {
                                    if (isShowMore) {
                                        scrollToTop();
                                        if (headerContent.length > 0) {
                                            setSelectedPart(headerContent[0]);
                                        }
                                    } else {
                                        if (headerContent.length > 0) {
                                            scrollToPart(selectedPart);
                                        }
                                    }
                                    setIsShowMore((v) => !v);
                                }}
                                sx={{
                                    width: 'fit-content',
                                    fontWeight: 500,
                                    fontSize: { xs: 14, lg: 16 },
                                }}
                                endIcon={
                                    <KeyboardDoubleArrowDownIcon
                                        sx={{
                                            transform: isShowMore
                                                ? 'rotate(180deg)'
                                                : 'none',
                                        }}
                                    />
                                }
                                children={isShowMore ? 'Thu gọn' : 'Xem thêm'}
                            />
                        </Stack>
                    </Box>
                    <Stack
                        alignItems="stretch"
                        justifyContent="flex-start"
                        sx={{
                            py: 2,
                            px: 3,
                            width: '100%',
                            backgroundColor: 'white',
                            borderRadius: '0px 0px 8px 8px',
                        }}
                        spacing={2}>
                        {keywordsTypography}
                        <Caution />
                    </Stack>
                </Box>
            </Stack>
        </Stack>
    );
}
