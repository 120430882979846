import { Breadcrumbs, Link, Typography } from '@mui/material';
import {
    Home as HomeIcon,
    DoubleArrow as DoubleArrowIcon,
} from '@mui/icons-material';

import { lighten, darken } from '@mui/material/styles';

import { Link as RouterLink } from 'react-router-dom';

import useFaIcon from 'hooks/useFaIcon';
import {
    faPills,
    faPrescription,
    faTablets,
    faDroplet,
    faKitMedical,
    faPersonDotsFromLine,
    faThermometer,
} from '@fortawesome/free-solid-svg-icons';

const StyledLink = ({ to = '/', children, ...props }) => {
    return (
        <Link
            underline="none"
            component={RouterLink}
            sx={(theme) => ({
                fontSize: { xs: 14, lg: 16 },
                color: lighten(theme.palette.text.link, 0.3),
                fontWeight: 500,
                ':hover': {
                    color: darken(theme.palette.text.link, 0.2),
                },
            })}
            to={to}>
            {children}
        </Link>
    );
};

export default function SectionBreadcrumbs({ breadcrumbsData = [], ...props }) {
    const PillsIcon = useFaIcon(faPills);
    const RxIcon = useFaIcon(faPrescription);
    const TabletsIcon = useFaIcon(faTablets);
    const DropletIcon = useFaIcon(faDroplet);
    const KitMedicalIcon = useFaIcon(faKitMedical);
    const PersonDotsFromLineIcon = useFaIcon(faPersonDotsFromLine);
    const ThermometerIcon = useFaIcon(faThermometer);

    let iconMapping = {
        Thuốc: PillsIcon,
        'Thuốc kê đơn': RxIcon,
        'Thuốc không kê đơn': TabletsIcon,
        'Thực phẩm chức năng': DropletIcon,
        'Sản phẩm khác': KitMedicalIcon,
        'Chăm sóc cá nhân': PersonDotsFromLineIcon,
        'Thiết bị y tế': ThermometerIcon,
    };
    return (
        <div role="presentation">
            <Breadcrumbs
                separator={
                    <DoubleArrowIcon
                        sx={{
                            width: 10,
                            height: 10,
                            color: 'text.disabled',
                        }}
                    />
                }
                aria-label="breadcrumbs">
                <StyledLink>
                    <HomeIcon
                        fontSize="small"
                        sx={{
                            mt: -0.5,
                            p: '1.5px',
                            color: 'primary.main',
                            backgroundColor: 'rgba(0,0,0,0.08)',
                            ':hover': {
                                backgroundColor: 'white',
                                border: '1px solid rgba(0,0,0,0.16)',
                                transform: 'scale(1.3)',
                            },
                            borderRadius: '50%',
                            transition: 'transform 0.3s ease',
                        }}
                    />
                </StyledLink>
                {breadcrumbsData.map((breadcrumb, idx) => {
                    const isNavLink = breadcrumb?.isNavLink ?? true;
                    let Icon = iconMapping?.[breadcrumb.name] || null;

                    return isNavLink ? (
                        <StyledLink
                            key={'breadcrumb-' + idx}
                            to={breadcrumb.to}>
                            {Icon && (
                                <Icon
                                    sx={{
                                        color: 'inherit',
                                        fontSize: { xs: 12, lg: 14 },
                                        mr: 1,
                                    }}
                                />
                            )}
                            {breadcrumb.name}
                        </StyledLink>
                    ) : (
                        <Typography
                            key={'breadcrumb-' + idx}
                            variant="caption"
                            sx={{
                                color: 'text.primary',
                                fontSize: { xs: 14, lg: 16 },
                            }}>
                            {Icon && (
                                <Icon
                                    sx={{
                                        color: 'inherit',
                                        fontSize: { xs: 12, lg: 14 },
                                        mr: 1,
                                    }}
                                />
                            )}
                            {breadcrumb.name}
                        </Typography>
                    );
                })}
            </Breadcrumbs>
        </div>
    );
}
