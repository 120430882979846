import { useState, forwardRef, useMemo } from 'react';

import {
    Typography,
    Stack,
    useMediaQuery,
    Box,
    Popper,
    Paper,
} from '@mui/material';
import {
    Search as SearchIcon,
    History as HistoryIcon,
    Close as CloseIcon,
    ArrowBackIosNew as ArrowBackIosNewIcon,
} from '@mui/icons-material';

import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { searchHistoryActions } from 'store/searchHistory-slice.js';
import { systemActions } from 'store/system-slice.js';

import {
    CustomButton,
    CustomSearch,
    CustomChip,
    CustomList,
    CustomHighlightText,
} from 'UI';
import { customScrollbar } from 'config/customCSS';

function CustomPopper(props) {
    return <Popper {...props} placement="bottom-end" />;
}
function CustomPaper({ children, ...props }) {
    return (
        <Paper
            {...props}
            sx={(theme) => ({
                '& .MuiAutocomplete-noOptions': {
                    px: 0,
                },
                position: { xs: 'absolute', md: 'unset' },
                right: { xs: -17, md: 'unset' },
                top: { xs: 17, md: 'unset' },
                mt: { xs: 0, md: 1 },
                boxShadow: { xs: 'none', md: 1 },
                borderRadius: { xs: 0, md: '15px' },
                border: {
                    xs: 'none',
                    md: `1px solid ${theme.palette.primary.main}`,
                },
                height: { xs: 'calc(100vh - 80px)', md: 'auto' },
                width: {
                    xs: '100vw',
                    md: '100%',
                },
                ...customScrollbar,
            })}>
            {children}
        </Paper>
    );
}
const CustomListbox = forwardRef(function CustomListbox(
    { children, ...props },
    ref
) {
    return (
        <Box
            component={'ul'}
            ref={ref}
            {...props}
            sx={{
                maxHeight: { xs: '100vh', md: '60vh' },
                padding: '0px !important',
            }}>
            {children}
        </Box>
    );
});
const CustomSearchButton = ({ ...props }) => {
    return (
        <CustomButton
            id="search-buttonId"
            useIconButton
            sx={{ p: 0, mr: -4 }}
            children={
                <SearchIcon
                    sx={(theme) => ({
                        width: theme.breakpoints.up('md') ? 37 : 32,
                        height: theme.breakpoints.up('md') ? 37 : 32,
                        backgroundColor: 'primary.main',
                        borderRadius: '50%',
                        p: 0.75,
                        color: 'white',
                    })}
                />
            }
            {...props}
        />
    );
};
export default function SearchAppbar({
    open = false,
    onOpen = () => {},
    ...otherProps
}) {
    let location = useLocation();
    const keyword = new URLSearchParams(location.search).get('s');
    const decodedKeyword = useMemo(() => {
        return keyword ? decodeURIComponent(keyword) : '';
    }, [keyword]);

    const dispatch = useDispatch();
    const searchHistory = useSelector((state) => state.searchHistory);

    const navigate = useNavigate();
    const isBiggerMdScreen = useMediaQuery((theme) =>
        theme.breakpoints.up('md')
    );

    const [searchValue, setSearchValue] = useState(
        decodedKeyword
            ? {
                  type: 'keyword',
                  id: decodedKeyword,
                  value: (
                      <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          py={0.5}>
                          <SearchIcon color="info" fontSize="small" />
                          <CustomHighlightText
                              label={decodedKeyword}
                              keyword={decodedKeyword}
                          />
                      </Stack>
                  ),
              }
            : null
    );
    const [searchTerm, setSearchTerm] = useState(decodedKeyword || '');

    const handleDeleteItemSearchHistory = (searchTerm) => {
        dispatch(searchHistoryActions.deleteItemSearchHistory(searchTerm));
    };
    const handleClearSearchHistory = () => {
        dispatch(searchHistoryActions.clearSearchHistory());
    };
    const handleAddSearchHistory = (searchTerm) => {
        dispatch(searchHistoryActions.addSearchHistory(searchTerm));
    };
    const handleOpen = () => {
        onOpen(true);
        dispatch(systemActions.toggleDimBackground(true));
        document.body.style.overflow = 'hidden';
    };
    const handleClose = (reason) => {
        if (!isBiggerMdScreen && reason !== 'selectOption') {
            return;
        }
        onOpen(false);
        dispatch(systemActions.toggleDimBackground(false));
        document.body.style.overflow = '';
    };

    const handleSearch = (searchTerm) => {
        if (searchTerm) {
            handleAddSearchHistory(searchTerm);
            setSearchValue({
                type: 'keyword',
                id: searchTerm,
                value: (
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        py={0.5}>
                        <SearchIcon color="info" fontSize="small" />
                        <CustomHighlightText
                            label={searchTerm}
                            keyword={searchTerm}
                        />
                    </Stack>
                ),
            });
            setTimeout(() => {
                navigate('/tim-kiem?s=' + searchTerm);
            }, 100);
        }
    };
    const handleChange = (newValue, reason) => {
        if (newValue) {
            if (newValue.type === 'keyword' && newValue.id) {
                handleClose('selectOption');
                handleSearch(newValue.id);
            } else if (
                newValue.type === 'product-item' &&
                reason === 'selectOption'
            ) {
                handleClose('selectOption');
                setTimeout(() => {
                    navigate(`/san-pham${newValue.slug}`);
                }, 100);
            } else if (newValue.type === 'see-more') {
                handleClose('selectOption');
                handleSearch(searchTerm);
            }
        } else {
            setSearchValue(null);
        }
    };
    let suggestion = (
        <Stack alignItems="flex-start" spacing={1.5}>
            {searchHistory.length > 0 && (
                <Stack alignItems="flex-start" width="100%">
                    <Stack
                        px={2.5}
                        width="100%"
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between">
                        <Typography
                            sx={{
                                fontWeight: 500,
                                color: 'text.primary',
                            }}>
                            Lịch sử tìm kiếm
                        </Typography>
                        <CustomButton
                            id="clear-all-search-history-custom-button"
                            sx={{ borderRadius: '20px', px: 1 }}
                            onClick={handleClearSearchHistory}
                            children="Xóa tất cả"
                        />
                    </Stack>
                    <CustomList
                        id="searchHistory-listId"
                        ListProps={{ sx: { width: '100%', py: 0 } }}
                        ListItemProps={{
                            sx: (theme) => ({
                                '&.MuiListItem-root': {
                                    p: '4px 12px',
                                    cursor: 'pointer',
                                    ':hover': {
                                        backgroundColor: 'disabled.lighter',
                                    },
                                },
                            }),
                        }}
                        alignItems="center"
                        density="compact"
                        items={searchHistory.slice(0, 5).map((term, idx) => ({
                            name: term + idx,
                            subLabel: null,
                            label: (
                                <Stack
                                    width="100%"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{ ml: -1 }}>
                                    <CustomButton
                                        id={`${term}-${idx}-buttonId`}
                                        color="lightgray"
                                        fullWidth
                                        disableEffect
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            p: 0,
                                            fontWeight: 400,
                                            ':hover': {
                                                color: 'blue',
                                                fontWeight: 500,
                                            },
                                        }}
                                        startIcon={
                                            <HistoryIcon
                                                sx={{ width: 22, height: 22 }}
                                            />
                                        }
                                        onClick={() => {
                                            handleClose('selectOption');
                                            handleSearch(term);
                                        }}>
                                        <Typography
                                            color="primary"
                                            sx={{ fontWeight: 'inherit' }}>
                                            {term}
                                        </Typography>
                                    </CustomButton>
                                    <CustomButton
                                        id={`${term}-${idx}-clear-button`}
                                        useIconButton
                                        color="disabled"
                                        onClick={() =>
                                            handleDeleteItemSearchHistory(term)
                                        }
                                        children={
                                            <CloseIcon fontSize="small" />
                                        }
                                    />
                                </Stack>
                            ),
                        }))}
                    />
                </Stack>
            )}
            <Box px={1}>
                <Typography
                    gutterBottom
                    sx={{
                        fontWeight: 500,
                        color: 'text.primary',
                        pl: 1.5,
                    }}>
                    Tra cứu hàng đầu
                </Typography>
                <Stack
                    direction="row"
                    flexWrap="wrap"
                    alignItems="center"
                    spacing={1}
                    pl={0.5}>
                    {[
                        'dung dịch vệ sinh',
                        'men vi sinh',
                        'sữa rửa mặt',
                        'siro tăng đề kháng',
                        'kem chống nắng',
                        'D3k2',
                        'canxi',
                    ].map((chip, idx) => {
                        return (
                            <CustomChip
                                key={chip}
                                size="medium"
                                label={chip}
                                color="disabled"
                                sx={{
                                    my: 0.5,
                                    ml: 1,
                                    fontWeight: 500,
                                }}
                                variant="outlined"
                                onClick={() => {
                                    handleSearch(chip);
                                    handleClose('selectOption');
                                }}
                            />
                        );
                    })}
                </Stack>
            </Box>
        </Stack>
    );
    let convertArrOptions = (options) => {
        return options
            .map(({ value, type }) => {
                if (type === 'product-item') {
                    return {
                        type,
                        id: value.shortName,
                        value: (
                            <Stack
                                justifyContent="flex-start"
                                width="100%"
                                p={1}>
                                <CustomHighlightText
                                    label={value.shortName}
                                    keyword={searchTerm}
                                />
                                <Box mt={1}>
                                    <Typography sx={{ fontSize: 14 }}>
                                        {value.webName}
                                    </Typography>
                                    {value.prescription && (
                                        <Typography
                                            variant="subttile2"
                                            sx={{ fontSize: 12 }}
                                            color="secondary">
                                            Cần tư vấn của dược sỹ
                                        </Typography>
                                    )}
                                    <Typography sx={{ fontSize: 12 }}>
                                        {
                                            value.category[
                                                value.category.length - 1
                                            ].name
                                        }
                                    </Typography>
                                </Box>
                            </Stack>
                        ),
                        avatar:
                            value.primaryImage.mainSrc ||
                            '../assets/images/khanhtranglogo-02.png',
                        ...value,
                    };
                } else if (type === 'keyword') {
                    return {
                        type,
                        id: value,
                        value: (
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                py={0.5}>
                                <SearchIcon color="info" fontSize="small" />
                                <CustomHighlightText
                                    label={value}
                                    keyword={searchTerm}
                                />
                            </Stack>
                        ),
                    };
                } else if (type === 'see-more') {
                    return {
                        type,
                        id: value,
                        value: (
                            <CustomButton
                                id="see-all-results-button"
                                disableEffect
                                fullWidth
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    textAlign: 'center',
                                }}
                                endIcon={
                                    <ArrowBackIosNewIcon
                                        sx={{
                                            transform: 'rotate(180deg)',
                                            width: 14,
                                            height: 14,
                                        }}
                                    />
                                }
                                onClick={() => {}}
                                children="Xem tất cả"
                            />
                        ),
                    };
                } else return null;
            })
            .filter((i) => i);
    };
    const handleClickSearchButton = () => {
        if (searchTerm) {
            handleSearch(searchTerm);
            handleClose('selectOption');
        } else {
            if (open) {
                handleClose('selectOption');
            } else {
                handleOpen();
            }
        }
    };

    return (
        <CustomSearch
            {...configProps}
            initialInputValue={decodedKeyword}
            value={searchValue}
            getInputValue={(inputValue) => {
                setSearchTerm(inputValue);
            }}
            onChange={handleChange}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    handleSearch(searchTerm);
                    handleClose('selectOption');
                }
            }}
            open={open}
            onOpen={handleOpen}
            onClose={(event, reason) => handleClose(reason)}
            convertArrOptions={convertArrOptions}
            initialText={suggestion}
            margin={isBiggerMdScreen ? 'dense' : 'none'}
            size={isBiggerMdScreen ? 'medium' : 'small'}
            openOnFocus={isBiggerMdScreen ? true : false}
            endAdornment={
                <CustomSearchButton onClick={handleClickSearchButton} />
            }
            {...otherProps}
        />
    );
}

let configProps = {
    id: 'search-appbar-id',
    name: 'appbar',
    variant: 'outlined',
    pathname: '/ecom/search/suggest',
    getOptionLabel: (option) => option?.id || '',
    groupBy: (option) => option.type,
    renderGroup: (params) => {
        return (
            <li key={params.key}>
                {params.key !== 0 && (
                    <Box
                        sx={{
                            mx: 'auto',
                            width: '98%',
                            height: '1px',
                            backgroundColor: 'primary.main',
                        }}
                    />
                )}
                <ul style={{ padding: 0 }}>{params.children}</ul>
            </li>
        );
    },
    getOptionKey: (option) => Math.random().toString(36).slice(2, 11),
    placeholder: 'Tìm tên thuốc, thực phẩm chức năng...',
    clearOnBlur: false,
    disableClearable: true,
    autoHighlight: false,
    filterOptions: (x) => x,
    PopperComponent: CustomPopper,
    PaperComponent: CustomPaper,
    ListboxComponent: CustomListbox,
};
