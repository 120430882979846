import { useState } from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Divider,
    Link,
    Box,
} from '@mui/material';
import {
    ChevronRight as ChevronRightIcon,
    PinDropRounded as PinDropRoundedIcon,
} from '@mui/icons-material';

import { Link as RouterLink, useNavigate } from 'react-router-dom';

export default function NavigationAccordion({
    data = {},
    onCloseMenu = () => {},
    ...props
}) {
    const navigate = useNavigate();

    const hasSelectedAccordion =
        data.subCategories.findIndex((x) => x.isSelected) > -1 || data.isSelected || false;

    const [expanded, setExpanded] = useState(
        hasSelectedAccordion ? `panel${data.id}` : false
    );

    const handleChange = (panel) => (event, newExpanded) => {
        event.preventDefault();
        event.currentTarget.blur();
        setExpanded(newExpanded ? panel : false);
    };

    const isExpanded = expanded === `panel${data.id}`;

    return (
        <Accordion
            expanded={isExpanded}
            onChange={handleChange(`panel${data.id}`)}
            sx={{
                width: '100%',
                boxShadow: 0,
                '&.MuiAccordion-root': { borderRadius: 0 },
            }}>
            <AccordionSummary
                sx={{
                    '&.MuiAccordionSummary-root': {
                        minHeight: 40,
                        backgroundColor: 'rgba(0,0,0,0.64)',
                    },
                    '& .MuiAccordionSummary-content': {
                        m: '12px 0',
                    },
                    '& .MuiAccordionSummary-content.Mui-expanded': {
                        m: '12px 0',
                    },
                    position: '-webkit-sticky',
                    // eslint-disable-next-line no-dupe-keys
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                }}
                expandIcon={
                    <ChevronRightIcon
                        sx={{
                            width: 'fit-content',
                            borderRadius: '50%',
                            transform: 'rotate(90deg)',
                            color: hasSelectedAccordion
                                ? 'warning.light'
                                : 'white',
                        }}
                    />
                }>
                <Typography
                    sx={{
                        textTransform: 'uppercase',
                        fontWeight: 600,
                        fontSize: 14,
                        color: hasSelectedAccordion ? 'warning.light' : 'white',
                    }}>
                    {data.mainCategory}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
                {data.subCategories.map((x, i) => {
                    return (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                backgroundColor: x?.isSelected
                                    ? 'warning.lighter'
                                    : 'rgba(0,0,0,0.12)',
                                color: 'text.primary',
                                borderLeft: 'none',
                                ':hover': {
                                    borderLeftWidth: x?.isSelected
                                        ? '0px'
                                        : '8px',
                                    borderLeftStyle: 'solid',
                                    borderLeftColor: x?.isSelected
                                        ? 'transparent'
                                        : 'warning.main',
                                    backgroundColor: 'warning.lighter',
                                },
                                transition: 'all 0.3s ease-in-out',
                                ...(i > 0 && {
                                    borderTopWidth: '0.5px',
                                    borderTopStyle: 'solid',
                                    borderTopColor: 'text.disabled',
                                }),
                            }}>
                            {x?.isSelected && (
                                <PinDropRoundedIcon
                                    sx={{ width: 14, height: 14, ml: 2 }}
                                />
                            )}
                            <Typography
                                component="h3"
                                sx={{
                                    width: '100%',
                                    maxHeight: 48,
                                    fontSize: 14,
                                    lineHeight: 1.2,
                                    fontWeight: x?.isSelected ? 600 : 400,
                                    textTransform: 'initial',
                                    textAlign: 'left',
                                    whiteSpace: 'initial',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '2',
                                    WebkitBoxOrient: 'vertical',
                                    color: 'inherit',
                                    pl: isExpanded
                                        ? x?.isSelected
                                            ? 1
                                            : 2
                                        : 5,
                                    pr: 1,
                                    py: 1,
                                    transition: isExpanded
                                        ? 'padding-left 0.3s ease-in-out'
                                        : 'padding-left 0.3s ease-in-out 0.5s',
                                }}>
                                <Link
                                    component={x?.isSelected ? 'p' : RouterLink}
                                    to={x?.isSelected ? undefined : x.slug}
                                    onClick={(e) => {
                                        if (x?.isSelected) {
                                            return;
                                        }
                                        e.preventDefault();
                                        onCloseMenu();
                                        setTimeout(() => navigate(x.slug), 50);
                                    }}
                                    underline="none"
                                    sx={{
                                        color: 'inherit',
                                        '&:hover': {
                                            color: 'text.primary',
                                            fontWeight: 600,
                                        },
                                    }}>
                                    {x.name}
                                </Link>
                            </Typography>
                        </Box>
                    );
                })}
            </AccordionDetails>
            <Divider />
        </Accordion>
    );
}
