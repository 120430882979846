import { createContext } from 'react';

export const ArticleContext = createContext({
    mainCategoryData: {},
    subCategoryData: {},
    currentCategoryLevel: 1,
    currentCategoryTitle: '',
    totalArticles: 0,
    viewType: 'vertical',
    toggleViewType: () => {},
});
