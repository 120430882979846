import { Stack, Skeleton, Box } from '@mui/material';

import { CustomMasonry } from 'UI';
import { LoadingModal } from 'template';

import ArticleCardSkeleton from 'shop/components/ArticleCardSkeleton';

export default function PageArticleSkeleton({ ...otherProps }) {
    return (
        <Stack sx={{ maxWidth: 1200, mx: 'auto', px: { xs: 0, sm: 2 } }}>
            <LoadingModal isLoading={true} />
            <Stack spacing={1} mt={1} pl={{ xs: 3, lg: 0 }}>
                <Skeleton variant="text" width={300} height={24} />
                <Skeleton variant="text" width={200} height={30} />
            </Stack>
            <Box width="100%" pt={2}>
                <Stack
                    mx={2}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Skeleton variant="text" width={200} height={30} />
                    <Skeleton variant="rounded" width={50} height={20} />
                </Stack>
                <CustomMasonry
                    spacing={2}
                    elevation={1}
                    id="article-card-sekeleton-masonryId"
                    columns={{ xs: 1 }}
                    items={Array.from({ length: 8 }, (x, i) => i).map(
                        (_, idx) => ({
                            id: 'skeleton-' + idx,
                            content: (
                                <ArticleCardSkeleton key={'skeleton-' + idx} />
                            ),
                        })
                    )}
                />
            </Box>
        </Stack>
    );
}
