import { useContext, useState } from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from '@mui/icons-material';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { ProductContentContext } from 'context/product-content-context.js';

import { CustomAvatar, CustomButton } from 'UI';

import ZoomImagesCustomDialog from './ZoomImagesCustomDialog.jsx';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function ProductGallery({ ...props }) {
    const { itemData } = useContext(ProductContentContext);

    const [activeStep, setActiveStep] = useState(0);
    const [isOpenZoomImagesDialog, setIsOpenZoomImagesDialog] = useState(false);

    const images = itemData.secondaryImages
        .filter((i) => i.isActive && i.status)
        .map((i) => i.mainSrc);

    const handleNext = () => {
        if (activeStep === images.length - 1) {
            return;
        }
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            return;
        }
        setActiveStep(activeStep - 1);
    };

    return (
        <Stack
            spacing={2}
            alignItems="center"
            pb={2}
            // eslint-disable-next-line no-dupe-keys
            sx={{ position: '-webkit-sticky', position: 'sticky', top: 0 }}>
            <Box
                p={{ xs: 3, sm: 1 }}
                sx={{
                    position: 'relative',
                    borderRadius: { xs: 0, lg: 4 },
                    overflow: 'hidden',
                    maxWidth: { xs: '100%', sm: '460px' },
                }}>
                {activeStep > 0 && (
                    <CustomButton
                        id="product-gallery-arrow-left-buttonId"
                        color="disabled"
                        useIconButton
                        onClick={handleBack}
                        sx={{
                            position: 'absolute',
                            left: 5,
                            top: '50%',
                            zIndex: 1,
                            border: '1px solid whitesmoke',
                            boxShadow: 1,
                            backgroundColor: 'whitesmoke',
                            ':hover': {
                                backgroundColor: 'whitesmoke',
                            },
                            p: 0.25,
                        }}
                        children={<KeyboardArrowLeftIcon fontSize="small" />}
                    />
                )}
                {activeStep < images.length - 1 && (
                    <CustomButton
                        id="product-gallery-arrow-right-buttonId"
                        color="disabled"
                        useIconButton
                        onClick={handleNext}
                        sx={{
                            position: 'absolute',
                            right: 5,
                            top: '50%',
                            zIndex: 1,
                            border: '1px solid whitesmoke',
                            boxShadow: 1,
                            backgroundColor: 'whitesmoke',
                            ':hover': {
                                backgroundColor: 'whitesmoke',
                            },
                            p: 0.25,
                        }}
                        children={
                            <KeyboardArrowLeftIcon
                                fontSize="small"
                                sx={{ transform: 'rotate(180deg)' }}
                            />
                        }
                    />
                )}
                <AutoPlaySwipeableViews
                    interval={5000}
                    axis="x"
                    autoplay={false}
                    index={activeStep}
                    onChangeIndex={(index) => setActiveStep(index)}>
                    {images.map((src, idx) => (
                        <CustomAvatar
                            key={src + idx}
                            variant="square"
                            loading="lazy"
                            src={src}
                            alt={'product-image-' + src + idx}
                            sx={{
                                width: '100%',
                                height: 'auto',
                                alignItems: 'flex-start',
                            }}
                            imgProps={{
                                width: '100%',
                                height: 'auto',
                                'object-fit': 'contain',
                            }}
                        />
                    ))}
                </AutoPlaySwipeableViews>
            </Box>
            <Stack
                direction="row"
                justifyContent="flex-start"
                spacing={1.5}
                flexWrap="nowrap"
                sx={{
                    overflow: 'hidden',
                    maxWidth: {
                        xs: 'calc(100% - 32px)',
                        sm: '460px',
                        lg: 'calc(100% - 32px)',
                    },
                    overflowX: 'scroll',
                    px: { xs: 1, sm: 0, lg: 1 },
                }}>
                {(images.length >= 4
                    ? images.slice(0, 4)
                    : images.concat(['../assets/images/khanhtranglogo-02.png'])
                ).map((src, idx, arr) => {
                    let selected =
                        activeStep === idx || (activeStep >= 3 && idx === 3);
                    let last = idx === arr.length - 1;

                    return (
                        <Stack sx={{ position: 'relative' }} key={src + idx}>
                            <CustomAvatar
                                src={src}
                                alt={src}
                                onClick={() => setActiveStep(idx)}
                                sx={{
                                    '&.MuiAvatar-root': {
                                        borderWidth: '1px',
                                        borderStyle: 'solid',
                                        borderColor: selected
                                            ? 'primary.main'
                                            : 'disabled.light',
                                        ':hover': {
                                            borderColor: 'primary.main',
                                        },
                                        opacity: last ? 0.5 : 1,
                                    },
                                    p: { xs: 0.5, sm: 2 },
                                    width: { xs: '100px', sm: '100px' },
                                    height: { xs: '100px', sm: '100px' },
                                    borderRadius: 2,
                                    cursor: 'pointer',
                                }}
                            />
                            {last && (
                                <Stack
                                    width="100%"
                                    height="100%"
                                    sx={(theme) => ({
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        backgroundColor: alpha(
                                            theme.palette.disabled.light,
                                            0.45
                                        ),
                                        borderRadius: 2,
                                        cursor: 'pointer',
                                    })}
                                    onClick={() =>
                                        setIsOpenZoomImagesDialog(true)
                                    }
                                    alignItems="center"
                                    justifyContent="center">
                                    <Typography
                                        sx={{
                                            color: 'text.secondary',
                                            textAlign: 'center',
                                            fontSize: 14,
                                            fontWeight: 600,
                                            maxWidth: 'calc(100% - 16px)',
                                            whiteSpace: 'normal',
                                        }}>
                                        Xem thêm
                                    </Typography>
                                </Stack>
                            )}
                        </Stack>
                    );
                })}
            </Stack>
            {isOpenZoomImagesDialog && (
                <ZoomImagesCustomDialog
                    open={isOpenZoomImagesDialog}
                    onClose={() => setIsOpenZoomImagesDialog(false)}
                />
            )}
        </Stack>
    );
}
