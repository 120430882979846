import { Stack, Box } from '@mui/material';

import { useParams, useLoaderData } from 'react-router-dom';
import domain from 'config/domain.js';
import { ProductContentContext } from 'context/product-content-context.js';

import { CustomHelmet } from 'UI';

import SectionBreadcrumbs from 'layout/section/SectionBreadcrumbs.jsx';
import Page404 from 'shop/404/Page404.jsx';
import ProductDataTable from './ProductDataTable.jsx';
import ProductDetails from './ProductDetails.jsx';
import RecentWatchProd from '../RecentWatchProd.jsx';
import SimilarProd from '../SimilarProd.jsx';

export default function PageProductContent({ ...otherProps }) {
    const { itemSlug } = useParams();
    const { itemData } = useLoaderData();

    if (itemSlug && !itemData) {
        return <Page404 />;
    }

    let {
        slug: iSlug,
        shortName: iShortName,
        category: iCategory,
        webName: iWebName,
        primaryImage: iPrimaryImage,
    } = itemData;
    let breadcrumbsData = iCategory
        .map((i, idx) => ({
            to: iCategory[0].slug + (idx === 0 ? '' : i.slug),
            name: i.name,
        }))
        .concat([{ to: iSlug, name: iShortName, isNavLink: false }]);

    let caution =
        iCategory?.[1]?.id === '01-1'
            ? 'Thuốc kê đơn chỉ bán khi có chỉ định của bác sĩ, mọi thông tin trên Website chỉ mang tính chất tham khảo.'
            : iCategory?.[1]?.id === '01-2'
            ? 'Thuốc nên được sử dụng theo chỉ định của bác sĩ, mọi thông tin trên Website chỉ mang tính chất tham khảo.'
            : iCategory?.[1]?.id?.startsWith('02')
            ? 'Thực phẩm bảo vệ sức khoẻ, không phải là thuốc, không có tác dụng thay thế thuốc chữa bệnh.'
            : '';

    const contextValue = { caution, itemData };

    return (
        <>
            <CustomHelmet
                slug={`https://nhathuockhanhtrang.com.vn${iSlug}`}
                title={`${iShortName} - Nhà thuốc Khánh Trang`}
                description={iWebName}
                image={iPrimaryImage.mainSrc}
            />
            <ProductContentContext.Provider value={contextValue}>
                <Stack
                    sx={{ maxWidth: 1200, mx: 'auto', px: { xs: 0, sm: 2 } }}>
                    <Box
                        sx={{
                            px: { xs: 3, sm: 0, md: 2 },
                            pt: { xs: 2, sm: 3 },
                            pb: { xs: 0, md: 1 },
                            backgroundColor: { xs: 'white', sm: 'unset' },
                        }}>
                        <SectionBreadcrumbs breadcrumbsData={breadcrumbsData} />
                    </Box>
                    <Stack spacing={2.5} my={{ xs: 0, sm: 2.5 }}>
                        <ProductDataTable />
                        <ProductDetails />
                        <div>
                            <SimilarProd
                                subCategorySlug={
                                    iCategory[iCategory.length - 1].slug
                                }
                            />
                            <RecentWatchProd />
                        </div>
                    </Stack>
                </Stack>
            </ProductContentContext.Provider>
        </>
    );
}

export const loader = async ({ params }) => {
    let { itemSlug } = params;
    if (itemSlug) {
        let response = await fetch(
            domain +
                '/ecom/product-item/content?itemSlug=' +
                itemSlug.replace('/', '')
        );
        if (!response.ok) {
            return { itemData: null };
        }
        let data = await response.json();
        return { itemData: data.data };
    }

    return { itemData: null };
};
