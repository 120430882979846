import { Stack, Typography, Box, Link, useMediaQuery } from '@mui/material';
import { AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { lighten } from '@mui/material/styles';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { borderWithTransition } from 'config/customCSS.js';
import transformDataToString from 'utils/transformDateToString';

import { CustomAvatar, CustomChip, CustomButton } from 'UI';

export default function ArticleCard({
    articleData = {},
    viewType = 'vertical',
    ...otherProps
}) {
    let {
        _id = Math.random().toString(36).slice(2, 11),
        category = [],
        slug = '',
        webName = '',
        description = '',
        primaryImage = {},
        modifiedAt = null,
    } = articleData;
    const navigate = useNavigate();

    let href = `/bai-viet${slug}`;

    const isBiggerSmScreen = useMediaQuery((theme) =>
        theme.breakpoints.up('sm')
    );

    const handleClick = (e) => {
        e.preventDefault();
        navigate(href);
    };

    let categoryName = category.find((i) => i.level === 2)?.name ?? ' ';
    let categorySlug =
        '/bai-viet-suc-khoe' + category.find((i) => i.level === 2)?.slug ?? '';

    if (viewType === 'vertical') {
        return (
            <Box
                key={_id + '-card-article-item'}
                sx={{
                    width: '100%',
                    borderTopWidth: '2px',
                    borderTopStyle: 'solid',
                    borderTopColor: 'disabled.light',
                    height: 400,
                }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ py: 1 }}>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 600,
                            color: 'secondary.main',
                            fontSize: 14,
                        }}>
                        <Link
                            component={RouterLink}
                            to={categorySlug}
                            underline="none"
                            sx={{
                                color: 'inherit',
                                '&:hover': { color: 'text.link' },
                            }}>
                            {categoryName}
                        </Link>
                    </Typography>
                    <Typography variant="caption">
                        {transformDataToString(
                            moment(modifiedAt).format('YYYY-MM-DD')
                        )}
                    </Typography>
                </Stack>
                <Box
                    sx={{
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                    <CustomAvatar
                        sx={{
                            width: '100%',
                            height: 160,
                            objectFit: 'cover',
                            borderRadius: '2px',
                        }}
                        src={primaryImage?.mainSrc || ''}
                        alt={primaryImage?.altText || ''}
                    />
                </Box>
                <Typography
                    component="h3"
                    gutterBottom
                    sx={{
                        pt: 1,
                        maxHeight: 62,
                        fontSize: 18,
                        fontWeight: 600,
                        textTransform: 'initial',
                        textAlign: 'left',
                        lineHeight: 1.4,
                        whiteSpace: 'initial',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                        color: 'text.primary',
                        '&:hover': { color: 'text.primary' },
                    }}>
                    <Link
                        component={RouterLink}
                        to={href}
                        underline="none"
                        sx={{
                            color: 'inherit',
                            '&:hover': { color: 'text.link' },
                        }}>
                        {webName}
                    </Link>
                </Typography>
                <Typography
                    gutterBottom
                    sx={{
                        maxHeight: 73,
                        fontSize: 14,
                        textTransform: 'initial',
                        textAlign: 'left',
                        lineHeight: 1.4,
                        whiteSpace: 'initial',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '3',
                        WebkitBoxOrient: 'vertical',
                        color: 'text.secondary',
                        '&:hover': { color: 'text.secondary' },
                    }}>
                    {description}
                </Typography>
                <CustomButton
                    id={_id + '-view-detail-custom-button'}
                    variant="contained"
                    disableEffect
                    sx={(theme) => ({
                        borderRadius: 1,
                        fontWeight: 600,
                        mt: 1,
                        width: 'fit-content',
                        py: 0.5,
                        px: 2,
                        backgroundColor: lighten(
                            theme.palette.primary.main,
                            0.08
                        ),
                    })}
                    onClick={handleClick}
                    children={`Đọc tiếp ${
                        categoryName === 'Tin khuyến mãi'
                            ? 'tin khuyến mãi'
                            : categoryName === 'Tin tức y dược'
                            ? 'tin tức'
                            : 'bài viết'
                    }`}
                />
            </Box>
        );
    } else
        return (
            <Stack
                key={_id + '-card-article-item'}
                sx={{
                    backgroundColor: 'white',
                    ...borderWithTransition,
                    width: '100%',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    p: 2,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                        transform: 'scale(1.01)',
                    },
                }}
                spacing={1.5}
                onClick={handleClick}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch">
                    <Box sx={{ width: 180 }}>
                        <CustomAvatar
                            sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'fill',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                            }}
                            src={primaryImage?.mainSrc || ''}
                            alt={primaryImage?.altText || ''}
                        />
                    </Box>
                    <Stack
                        sx={{ width: '100%', px: 1.5 }}
                        spacing={1}
                        alignItems="flex-start">
                        <CustomChip
                            color="secondary"
                            clickable={false}
                            sx={{
                                fontWeight: 500,
                                fontSize: 14,
                                px: 1.5,
                                borderRadius: 1,
                            }}
                            label={category.find((i) => i.level === 2).name}
                        />
                        <Typography
                            component="h3"
                            gutterBottom
                            sx={{
                                maxHeight: 48,
                                fontSize: { xs: 16, sm: 18 },
                                fontWeight: 500,
                                lineHeight: 1.5,
                                textTransform: 'initial',
                                textAlign: 'left',

                                whiteSpace: 'initial',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: isBiggerSmScreen ? '1' : '2',
                                WebkitBoxOrient: 'vertical',
                                color: 'primary.main',
                                '&:hover': { color: 'primary.main' },
                            }}>
                            {webName}
                        </Typography>
                        <Typography
                            noWrap
                            data-lines={isBiggerSmScreen ? '2' : '3'}
                            color="disabled"
                            sx={{
                                maxHeight: 73,
                                fontSize: { xs: 14, sm: 16 },
                                lineHeight: 1.4,
                                textAlign: 'left',
                                textTransform: 'initial',
                                whiteSpace: 'initial',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: isBiggerSmScreen ? '2' : '3',
                                WebkitBoxOrient: 'vertical',
                                color: 'text.secondary',
                                '&:hover': { color: 'text.secondary' },
                            }}>
                            {modifiedAt && (
                                <AccessTimeIcon
                                    sx={{
                                        color: 'disabled.light',
                                        width: 16,
                                        height: 16,
                                        verticalAlign: 'middle',
                                        mt: -0.25,
                                        mr: 0.5,
                                    }}
                                />
                            )}
                            <span>
                                {modifiedAt &&
                                    moment(modifiedAt).format('DD/MM/YYYY')}
                            </span>
                            {description && isBiggerSmScreen && (
                                <span>&nbsp;•&nbsp;</span>
                            )}
                            {isBiggerSmScreen && description}
                        </Typography>
                    </Stack>
                </Stack>
                {!isBiggerSmScreen && (
                    <Typography
                        gutterBottom
                        sx={{
                            maxHeight: 73,
                            fontSize: { xs: 14, sm: 16 },
                            lineHeight: 1.4,
                            textTransform: 'initial',
                            textAlign: 'left',
                            whiteSpace: 'initial',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                            color: 'text.secondary',
                            '&:hover': { color: 'text.secondary' },
                        }}>
                        {description}
                    </Typography>
                )}
            </Stack>
        );
}
