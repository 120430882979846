import { Stack, Typography, Box } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { CustomChip, CustomButton } from 'UI';

export default function ActiveFilterBox({
    chips = [],
    onClickResetButton = () => {},
    ...props
}) {
    return (
        <Box
            sx={{
                width: '100%',
                maxHeight: 100,
                overflowY: 'scroll',
            }}>
            <Stack
                sx={(theme) => ({
                    px: 2,
                    py: 1,
                    borderTop: `1px solid ${theme.palette.disabled.lighter}`,
                })}
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                spacing={1}>
                <Typography>{`Lọc theo (${chips.length})`}</Typography>
                {chips.map(({ chip, onDelete }, idx) => {
                    return (
                        <CustomChip
                            key={`${chip}-${idx}`}
                            color="disabled"
                            size="medium"
                            clickable={false}
                            label={chip}
                            sx={{
                                my: 0.5,
                                backgroundColor: 'white',
                                ':hover': {
                                    backgroundColor: 'white',
                                },
                            }}
                            deleteIcon={<CloseIcon />}
                            onDelete={onDelete}
                        />
                    );
                })}
                <CustomButton
                    id="clear-all-filters-custom-button"
                    varinat="contained"
                    disableEffect
                    onClick={onClickResetButton}
                    children={<b>Xóa tất cả</b>}
                />
            </Stack>
        </Box>
    );
}
