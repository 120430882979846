import { useState } from 'react';

import { Box, Menu, MenuItem } from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';

import { CustomButton } from 'UI';

import NavigationAccordion from 'shop/components/NavigationAccordion';

export default function NavigationButton({ menuItems, ...props }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <CustomButton
                id="navigationion-button-id"
                variant="contained"
                color="white"
                disableEffect
                aria-controls={
                    isMenuOpen ? 'quick-navigation-menuId' : undefined
                }
                aria-haspopup="true"
                aria-expanded={isMenuOpen ? 'true' : undefined}
                sx={{
                    borderRadius: 5,
                    fontWeight: 500,
                    borderWidth: '0.5px',
                    borderStyle: 'solid',
                    borderColor: 'disabled.light',
                    width: 'fit-content',
                }}
                endIcon={
                    <ChevronRightIcon
                        sx={{
                            width: 'fit-content',
                            borderRadius: '50%',
                            transform: isMenuOpen
                                ? 'rotate(-90deg)'
                                : 'rotate(90deg)',
                            transition: 'all 0.3s ease-in-out',
                        }}
                    />
                }
                onClick={handleOpenMenu}
                children={'Đi đến nhóm'}
            />
            <Menu
                id="quick-navigation-menuId"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleCloseMenu}
                sx={{ '& .MuiList-root': { p: 0, position: 'relative' } }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            borderRadius: '6px !important',
                            width: 250,
                            maxHeight: 300,
                            overflow: 'auto',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.08))',
                            mt: 1,
                        },
                    },
                }}>
                {menuItems.map((item, idx) => (
                    <MenuItem
                        key={'menu-' + idx}
                        disableRipple
                        sx={{
                            p: 0,
                            cursor: 'default',
                            minHeight: 'auto',
                        }}>
                        <NavigationAccordion
                            data={item}
                            onCloseMenu={handleCloseMenu}
                        />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
