import { Stack, Box, Typography, useMediaQuery } from '@mui/material';
import { Inventory2 as Inventory2Icon } from '@mui/icons-material';

import { useQuery } from '@tanstack/react-query';
import { getSimpleQuery } from 'utils/http.js';

import { CustomMasonry } from 'UI';

import ArticleCard from './ArticleCard';

export default function SimilarArticle({
    keywordIds = [],
    currentSlug = '',
    ...otherProps
}) {
    const isBiggerMdScreen = useMediaQuery((theme) =>
        theme.breakpoints.up('md')
    );

    const { data } = useQuery({
        queryKey: ['article-item', { keywordIds }],
        queryFn: ({ signal }) =>
            getSimpleQuery({
                signal,
                apiUrl: `/ecom/article-item/data?keywordIds=${keywordIds.join(
                    ','
                )}&type=1&skip=0&take=20`,
            }),
        staleTime: 30000,
        retry: 0,
        enabled: keywordIds.length > 0,
    });

    if (
        keywordIds.length === 0 ||
        !(data && data.data.filter((i) => i.slug !== currentSlug).length > 0)
    ) {
        return null;
    }

    return (
        <Box
            sx={{
                px: { xs: 2, sm: 2 },
                pt: { xs: 3, sm: 0 },
                pb: 2,
            }}>
            <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ pl: { xs: 1, sm: 2 }, pb: 1 }}>
                <Inventory2Icon
                    sx={{
                        width: { xs: 26, lg: 28 },
                        height: { xs: 26, lg: 28 },
                        color: 'primary.main',
                    }}
                />
                <Typography
                    variant="h2"
                    sx={{
                        color: 'primary.main',
                        fontSize: { xs: 20, lg: 22 },
                        fontWeight: 600,
                        lineHeight: { xs: 1.4, sm: 1.5 },
                    }}>
                    Bài viết tương tự
                </Typography>
            </Stack>
            <CustomMasonry
                spacing={{ xs: 1, sm: 2 }}
                elevation={1}
                id="similar-article-masonry-id"
                columns={{ xs: 1, md: 2, xl: 3 }}
                items={data.data
                    .filter((i) => i.slug !== currentSlug)
                    .map((articleData) => {
                        return {
                            id: articleData._id,
                            content: (
                                <ArticleCard
                                    articleData={articleData}
                                    viewType={
                                        isBiggerMdScreen
                                            ? 'vertical'
                                            : 'horizontal'
                                    }
                                />
                            ),
                        };
                    })}
                wrap={false}
            />
        </Box>
    );
}
