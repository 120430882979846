import { useState } from 'react';

import {
    Box,
    Drawer,
    ListItemText,
    Typography,
    Stack,
    ListItemButton,
    Collapse,
} from '@mui/material';
import {
    Login as LoginIcon,
    ExpandLess as ExpandLessIcon,
    EmojiEvents as EmojiEventsIcon,
    Face as FaceIcon,
    Face2 as Face2Icon,
    MenuOpenRounded as MenuOpenRoundedIcon,
    PinDrop as PinDropIcon,
    Schedule as ScheduleIcon,
    WhatsApp as WhatsAppIcon,
    Share as ShareIcon,
} from '@mui/icons-material';
import { lighten } from '@mui/material/styles';

import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import breadcrumbNameMapping from 'data/breadcrumbNameMapping.json';
import cateLv2 from 'data/cateLv2.json';
import articleLv1 from 'data/articleLv1.json';

import { CustomButton, CustomAvatar, CustomList } from 'UI';

import LogoBrand from '../LogoBrand.jsx';

import { POLICIES } from 'shop/policy/PagePolicy.jsx';

const StyledBox = ({ children, ...props }) => {
    return (
        <Box
            sx={{
                backgroundImage: `url('../assets/images/pharmacy-illustration.png')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '140px 90px',
                backgroundPosition: '5% 50%',
                ':hover': {
                    backgroundColor: 'rgba(226,237,255)',
                },
                py: 2,
                border: 'none',
                backgroundColor: 'rgba(226,237,255)',
                width: '100%',
                height: '100px',
                position: 'relative',
            }}>
            {children}
        </Box>
    );
};

const ListOptionButtonLabel = ({
    title = 'Thuốc',
    isOpen = false,
    toggleCollapse = () => {},
    selected = false,
    ...props
}) => {
    return (
        <CustomButton
            id={`${title}-button-id`}
            disableEffect
            sx={{
                py: 0,
                px: 1,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
            onClick={toggleCollapse}>
            <ListItemText
                primary={title}
                primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: selected ? 'primary.main' : 'text.secondary',
                    fontSize: 16,
                    fontWeight: selected ? 600 : 500,
                    textAlign: 'left',
                    textTransform: 'initial',
                }}
            />
            <ExpandLessIcon
                sx={(theme) => ({
                    color: selected ? 'primary.main' : 'text.secondary',
                    transform: isOpen ? 'rotate(180deg)' : 'rotate(90deg)',
                    borderRadius: '50%',
                    backgroundColor: selected
                        ? lighten(theme.palette.primary.lighter, 0.6)
                        : 'whitesmoke',
                    p: 0.25,
                    transition: 'all 0.3s ease-in-out',
                })}
            />
        </CustomButton>
    );
};

const CustomSubList = ({
    items = [],
    mainCategorySlug = '/thuoc',
    selectOption = () => {},
    ...props
}) => {
    const location = useLocation().pathname;

    return (
        <CustomList
            alignItems="center"
            density="compact"
            ListProps={{
                sx: {
                    m: 1,
                    mb: 2,
                    py: 0.5,
                    backgroundColor: 'aliceblue',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'info.main',
                    borderRadius: 2,
                },
            }}
            ListItemProps={{
                sx: { px: 0, mx: 'auto', width: 'calc(100% - 16px)' },
            }}
            items={items.map(({ id, name, slug }) => {
                let fullSlug = mainCategorySlug + slug;

                return {
                    name: id,
                    subLabel: null,
                    divider: name === 'Tất cả',
                    label: (
                        <ListItemButton
                            onClick={() => selectOption(fullSlug)}
                            disableRipple
                            disableTouchRipple
                            sx={{
                                fontSize: 14,
                                fontWeight:
                                    name === 'Tất cả' || location === fullSlug
                                        ? 500
                                        : 400,
                                px: 0,
                                py: 0.5,
                                color:
                                    location === fullSlug
                                        ? 'text.link'
                                        : 'text.secondary',
                                ':hover': {
                                    color: 'text.link',
                                    fontWeight:
                                        name === 'Tất cả' ||
                                        location === fullSlug
                                            ? 500
                                            : 400,
                                    backgroundColor: 'transparent',
                                },
                            }}>
                            {name}
                        </ListItemButton>
                    ),
                };
            })}
            {...props}
        />
    );
};

const AccountHeader = ({ openLoginDialog, openLogoutDialog, ...props }) => {
    const userInfo = useSelector((state) => state.userInfo.value);
    let {
        _id = null,
        username = '',
        gender = 'male',
        tel = '',
        rewardPoint = null,
    } = userInfo;

    if (!_id)
        return (
            <Stack
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1.5}
                sx={{
                    px: 3,
                    py: 1,
                    backgroundColor: 'primary.main',
                }}>
                <Stack spacing={0.5}>
                    <Typography
                        variant="subtitle2"
                        sx={{ fontSize: 12, color: 'white' }}>
                        Bạn chưa đăng nhập.
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{ fontSize: 12, color: 'white' }}>
                        Hãy đăng nhập để xem các khuyến mãi dành riêng cho thành
                        viên.
                    </Typography>
                </Stack>
                <CustomButton
                    id="drawer-login-buttonId"
                    variant="contained"
                    color="success"
                    disableEffect
                    onClick={openLoginDialog}
                    sx={{ fontWeight: 500 }}
                    startIcon={<LoginIcon />}
                    children="Đăng nhập"
                />
            </Stack>
        );

    let Icon = gender === 'male' ? FaceIcon : Face2Icon;
    return (
        <Box
            sx={{
                backgroundColor: 'aliceblue',
                p: 2,
            }}>
            <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between">
                <Stack direction="row" spacing={1} alignItems="flex-start">
                    <CustomAvatar avatarSize={35}>
                        <Icon
                            color="primary"
                            sx={{
                                p: 0.5,
                                backgroundColor: 'rgba(0,0,0,0.04)',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderColor: 'primary.light',
                                borderRadius: '50%',
                            }}
                            fontSize="large"
                        />
                    </CustomAvatar>
                    <Box>
                        <Typography
                            noWrap
                            variant="subtitle2"
                            color="primary"
                            sx={{
                                fontSize: 16,
                                maxWidth: 160,
                                lineHeight: 0.8,
                            }}>
                            {username}
                        </Typography>
                        <Typography variant="caption">{tel}</Typography>
                    </Box>
                </Stack>
                <CustomButton
                    id="drawer-logout-buttonId"
                    disableEffect
                    variant="contained"
                    sx={{
                        width: 'fit-content',
                        px: 1.25,
                        py: 0.5,
                        borderRadius: 5,
                        fontWeight: 300,
                    }}
                    onClick={openLogoutDialog}
                    children="Đăng xuất"
                />
            </Stack>
            {rewardPoint && (
                <Stack direction="row" spacing={1} alignItems="center">
                    <EmojiEventsIcon color="warning" />
                    <Typography color="primary">
                        {'Điểm tích lũy: '}
                        {Number(rewardPoint).toLocaleString() + ' điểm'}
                    </Typography>
                </Stack>
            )}
        </Box>
    );
};

const DRAWER_WIDTH = 320;

export default function SectionDrawer({
    openLogoutDialog = () => {},
    openLoginDialog = () => {},
    open = false,
    onClose = () => {},
    ...otherProps
}) {
    const navigate = useNavigate();
    const location = useLocation().pathname;

    const [isOpenCollapse, setIsOpenCollapse] = useState({
        drug: location.includes('/thuoc'),
        supplement: location.includes('/thuc-pham-chuc-nang'),
        otherProduct: location.includes('/san-pham-khac'),
        article: location.includes('/bai-viet-suc-khoe'),
        adminPage: true,
        adminList: true,
        policy: POLICIES.map((i) => i.pathname).includes(location),
        contact: true,
    });
    const toggleCollapse = (name) => {
        setIsOpenCollapse((prev) => {
            let updatedValue = { ...prev };
            updatedValue[name] = !updatedValue[name];
            return updatedValue;
        });
    };

    const selectDrawerOption = (pathname) => {
        onClose();
        setTimeout(() => navigate(pathname), 100);
    };

    return (
        <Box component="nav">
            <Drawer
                variant="temporary"
                open={open}
                onClose={onClose}
                ModalProps={{ keepMounted: true }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: DRAWER_WIDTH,
                    },
                    height: '100vh',
                }}>
                <Stack justifyContent="space-between" height="100%">
                    <div>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ p: 0.5, pr: 1 }}>
                            <LogoBrand
                                styleProps={{ marginLeft: '-14px' }}
                                width="160px"
                                height="60px"
                            />
                            <CustomButton
                                id="drawer-close-buttonId"
                                disableEffect
                                useIconButton
                                onClick={onClose}
                                children={
                                    <MenuOpenRoundedIcon
                                        sx={{
                                            width: 30,
                                            height: 30,
                                            color: 'text.secondary',
                                            borderRadius: '50%',
                                            backgroundColor: 'rgba(0,0,0,0.06)',
                                            p: 0.5,
                                        }}
                                    />
                                }
                            />
                        </Stack>
                        <AccountHeader
                            openLoginDialog={openLoginDialog}
                            openLogoutDialog={openLogoutDialog}
                        />
                        <CustomList
                            ListProps={{ sx: { mb: 1, mx: 1 } }}
                            id="drawer-listId"
                            alignItems="center"
                            disableGutters
                            density="comfortable"
                            items={[
                                {
                                    name: 'drug',
                                    label: (
                                        <ListOptionButtonLabel
                                            title={
                                                breadcrumbNameMapping['/thuoc']
                                            }
                                            isOpen={isOpenCollapse.drug}
                                            toggleCollapse={() =>
                                                toggleCollapse('drug')
                                            }
                                            selected={location.startsWith(
                                                '/thuoc'
                                            )}
                                        />
                                    ),
                                    subLabel: (
                                        <Collapse
                                            in={isOpenCollapse.drug}
                                            timeout="auto">
                                            <CustomSubList
                                                id="drug-sub-list-id"
                                                mainCategorySlug="/thuoc"
                                                items={[
                                                    {
                                                        id: '01-0',
                                                        name: 'Tất cả',
                                                        slug: '',
                                                    },
                                                ].concat(
                                                    cateLv2.filter(
                                                        (i) =>
                                                            i.parentName ===
                                                            'Thuốc'
                                                    )
                                                )}
                                                selectOption={
                                                    selectDrawerOption
                                                }
                                            />
                                        </Collapse>
                                    ),
                                    divider: true,
                                },
                                {
                                    name: 'supplement',
                                    label: (
                                        <ListOptionButtonLabel
                                            title={
                                                breadcrumbNameMapping[
                                                    '/thuc-pham-chuc-nang'
                                                ]
                                            }
                                            isOpen={isOpenCollapse.supplement}
                                            toggleCollapse={() =>
                                                toggleCollapse('supplement')
                                            }
                                            selected={location.startsWith(
                                                '/thuc-pham-chuc-nang'
                                            )}
                                        />
                                    ),
                                    subLabel: (
                                        <Collapse
                                            in={isOpenCollapse.supplement}
                                            timeout="auto">
                                            <CustomSubList
                                                id="supplement-sub-list-id"
                                                mainCategorySlug="/thuc-pham-chuc-nang"
                                                items={[
                                                    {
                                                        id: '02-0',
                                                        name: 'Tất cả',
                                                        slug: '',
                                                    },
                                                ].concat(
                                                    cateLv2.filter(
                                                        (i) =>
                                                            i.parentName ===
                                                            'Thực phẩm chức năng'
                                                    )
                                                )}
                                                selectOption={
                                                    selectDrawerOption
                                                }
                                            />
                                        </Collapse>
                                    ),
                                    divider: true,
                                },
                                {
                                    name: 'otherProduct',
                                    label: (
                                        <ListOptionButtonLabel
                                            title={
                                                breadcrumbNameMapping[
                                                    '/san-pham-khac'
                                                ]
                                            }
                                            isOpen={isOpenCollapse.otherProduct}
                                            toggleCollapse={() =>
                                                toggleCollapse('otherProduct')
                                            }
                                            selected={location.startsWith(
                                                '/san-pham-khac'
                                            )}
                                        />
                                    ),
                                    subLabel: (
                                        <Collapse
                                            in={isOpenCollapse.otherProduct}
                                            timeout="auto">
                                            <CustomSubList
                                                id="otherProduct-sub-list-id"
                                                mainCategorySlug="/san-pham-khac"
                                                items={[
                                                    {
                                                        id: '03-0',
                                                        name: 'Tất cả',
                                                        slug: '',
                                                    },
                                                ].concat(
                                                    cateLv2.filter(
                                                        (i) =>
                                                            i.parentName ===
                                                            'Sản phẩm khác'
                                                    )
                                                )}
                                                selectOption={
                                                    selectDrawerOption
                                                }
                                            />
                                        </Collapse>
                                    ),
                                    divider: true,
                                },
                                {
                                    name: 'article',
                                    label: (
                                        <ListOptionButtonLabel
                                            title={
                                                breadcrumbNameMapping[
                                                    '/bai-viet-suc-khoe'
                                                ]
                                            }
                                            isOpen={isOpenCollapse.article}
                                            toggleCollapse={() =>
                                                toggleCollapse('article')
                                            }
                                            selected={location.startsWith(
                                                '/bai-viet-suc-khoe'
                                            )}
                                        />
                                    ),
                                    subLabel: (
                                        <Collapse
                                            in={isOpenCollapse.article}
                                            timeout="auto">
                                            <CustomSubList
                                                id="article-sub-list-id"
                                                mainCategorySlug="/bai-viet-suc-khoe"
                                                items={[
                                                    {
                                                        id: '00',
                                                        name: 'Tất cả',
                                                        slug: '',
                                                    },
                                                ].concat(articleLv1)}
                                                selectOption={
                                                    selectDrawerOption
                                                }
                                            />
                                        </Collapse>
                                    ),
                                    divider: true,
                                },
                                {
                                    name: 'adminPage',
                                    label: (
                                        <ListOptionButtonLabel
                                            title={'Quản lý Page'}
                                            isOpen={isOpenCollapse.adminPage}
                                            toggleCollapse={() =>
                                                toggleCollapse('adminPage')
                                            }
                                            selected={[
                                                '/admin/homepage',
                                                '/admin/market',
                                                '/admin/product-item',
                                                '/admin/manufacture',
                                                '/admin/ingredient',
                                                '/admin/article-item',
                                                '/admin/keyword',
                                            ].includes(location)}
                                        />
                                    ),
                                    subLabel: (
                                        <Collapse
                                            in={isOpenCollapse.adminPage}
                                            timeout="auto">
                                            <CustomSubList
                                                id="adminPage-sub-list-id"
                                                mainCategorySlug=""
                                                items={[
                                                    '/admin/homepage',
                                                    '/admin/market',
                                                    '/admin/product-item',
                                                    '/admin/manufacture',
                                                    '/admin/ingredient',
                                                    '/admin/article-item',
                                                    '/admin/keyword',
                                                ].map((i) => ({
                                                    id: i,
                                                    name: breadcrumbNameMapping[
                                                        i
                                                    ],
                                                    slug: i,
                                                }))}
                                                selectOption={
                                                    selectDrawerOption
                                                }
                                            />
                                        </Collapse>
                                    ),
                                    divider: true,
                                },
                                {
                                    name: 'adminList',
                                    label: (
                                        <ListOptionButtonLabel
                                            title={'Danh sách'}
                                            isOpen={isOpenCollapse.adminList}
                                            toggleCollapse={() =>
                                                toggleCollapse('adminList')
                                            }
                                            selected={[
                                                '/admin/staff',
                                                '/admin/commission-products',
                                                '/admin/salary',
                                            ].includes(location)}
                                        />
                                    ),
                                    subLabel: (
                                        <Collapse
                                            in={isOpenCollapse.adminList}
                                            timeout="auto">
                                            <CustomSubList
                                                id="adminList-sub-list-id"
                                                mainCategorySlug=""
                                                items={[
                                                    '/admin/staff',
                                                    '/admin/commission-products',
                                                    '/admin/salary',
                                                ].map((i) => ({
                                                    id: i,
                                                    name: breadcrumbNameMapping[
                                                        i
                                                    ],
                                                    slug: i,
                                                }))}
                                                selectOption={
                                                    selectDrawerOption
                                                }
                                            />
                                        </Collapse>
                                    ),
                                    divider: true,
                                },
                                {
                                    name: 'policy',
                                    label: (
                                        <ListOptionButtonLabel
                                            title={'Quy chế hoạt động'}
                                            isOpen={isOpenCollapse.policy}
                                            toggleCollapse={() =>
                                                toggleCollapse('policy')
                                            }
                                            selected={Boolean(
                                                POLICIES.find(
                                                    (i) =>
                                                        i.pathname === location
                                                )
                                            )}
                                        />
                                    ),
                                    subLabel: (
                                        <Collapse
                                            in={isOpenCollapse.policy}
                                            timeout="auto">
                                            <CustomSubList
                                                id="policy-sub-list-id"
                                                mainCategorySlug=""
                                                items={POLICIES.map(
                                                    ({ pathname }) => ({
                                                        id: pathname,
                                                        name: breadcrumbNameMapping[
                                                            pathname
                                                        ],
                                                        slug: pathname,
                                                    })
                                                )}
                                                selectOption={
                                                    selectDrawerOption
                                                }
                                            />
                                        </Collapse>
                                    ),

                                    divider: true,
                                },
                                {
                                    name: 'contact',
                                    label: (
                                        <ListOptionButtonLabel
                                            title={'Liên hệ'}
                                            isOpen={isOpenCollapse.contact}
                                            toggleCollapse={() =>
                                                toggleCollapse('contact')
                                            }
                                            selected={false}
                                        />
                                    ),
                                    subLabel: (
                                        <Collapse
                                            in={isOpenCollapse.contact}
                                            timeout="auto">
                                            <CustomList
                                                id="contact-sub-list-id"
                                                alignItems="center"
                                                density="compact"
                                                ListProps={{
                                                    sx: {
                                                        m: 1,
                                                        py: 0.5,
                                                        backgroundColor:
                                                            'aliceblue',
                                                        borderWidth: '1px',
                                                        borderStyle: 'solid',
                                                        borderColor:
                                                            'info.main',
                                                        borderRadius: 2,
                                                    },
                                                }}
                                                ListItemProps={{
                                                    sx: {
                                                        px: 0,
                                                        mx: 'auto',
                                                        width: 'calc(100% - 16px)',
                                                    },
                                                }}
                                                items={contactItems}
                                            />
                                        </Collapse>
                                    ),
                                },
                            ].filter((i) =>
                                location.startsWith('/admin')
                                    ? i
                                    : i.name !== 'adminList' &&
                                      i.name !== 'adminPage'
                            )}
                        />
                    </div>
                    <StyledBox>
                        <Stack
                            alignItems="center"
                            sx={{
                                position: 'absolute',
                                right: '5%',
                                top: '20%',
                            }}>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    textAlign: 'center',
                                }}>
                                {'Hotline Zalo hỗ trợ'}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    textAlign: 'center',
                                }}>
                                {'(08:00 - 21:30)'}
                            </Typography>
                            <Typography
                                component="a"
                                href="tel:0704430919"
                                color="info"
                                sx={{
                                    fontSize: 18,
                                    fontWeight: 600,
                                    pt: 0.5,
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    textDecoration: 'none',
                                }}>
                                070.443.0919
                            </Typography>
                        </Stack>
                    </StyledBox>
                </Stack>
            </Drawer>
        </Box>
    );
}

export const contactItems = [
    {
        name: 'phone-contact',
        icon: (
            <WhatsAppIcon
                sx={{ color: 'primary.main', mt: -0.2 }}
                fontSize="small"
            />
        ),
        subLabel: null,
        label: (
            <ListItemText
                primary="070.443.0919"
                primaryTypographyProps={{
                    component: 'a',
                    href: 'tel:0704430919',
                    sx: {
                        fontSize: 14,
                        fontWeight: 500,
                        '&:hover': {
                            color: 'primary.dark',
                            backgroundColor: 'transparent',
                            textDecoration: 'underline',
                        },
                        textDecoration: 'none',
                        color: 'text.link',
                    },
                }}
                secondary="Zalo | 08:00 - 21:30"
                secondaryTypographyProps={{
                    sx: { color: 'text.secondary', fontSize: 12 },
                }}
            />
        ),
    },
    {
        name: 'open-time',
        icon: <ScheduleIcon sx={{ color: 'primary.main' }} fontSize="small" />,
        subLabel: null,
        label: (
            <ListItemText
                primary="Thứ 2 - Chủ nhật | 06:30 - 22:15"
                primaryTypographyProps={{
                    sx: { color: 'text.primary', fontSize: 14 },
                }}
                secondary="không nghỉ Lễ, Tết"
                secondaryTypographyProps={{
                    sx: { color: 'text.secondary', fontSize: 12 },
                }}
            />
        ),
    },
    {
        name: 'address-contact',
        icon: <PinDropIcon sx={{ color: 'primary.main' }} fontSize="small" />,
        subLabel: null,
        label: (
            <ListItemText
                primary="124 Nguyễn Thị Tú, P. Bình Hưng Hoà B, Q. Bình Tân, TP. HCM"
                primaryTypographyProps={{
                    sx: { color: 'text.primary', fontSize: 14 },
                }}
                secondary="Xem trên Google Maps"
                secondaryTypographyProps={{
                    'aria-label': 'google maps icon',
                    component: 'a',
                    href: 'https://www.google.com/maps/place/Nh%C3%A0+Thu%E1%BB%91c+T%C6%B0+Nh%C3%A2n+Kh%C3%A1nh+Trang/@10.8158692,106.5945157,16.77z/data=!4m6!3m5!1s0x31752b97cd17fb6f:0xb3ee2a6fe105213!8m2!3d10.8158685!4d106.5966005!16s%2Fg%2F11xhbyt7_',
                    sx: {
                        fontSize: 12,
                        '&:hover': {
                            color: 'primary.dark',
                            backgroundColor: 'transparent',
                            textDecoration: 'underline',
                        },
                        textDecoration: 'none',
                        color: 'text.link',
                    },
                }}
            />
        ),
    },
    {
        name: 'social-media-contact',
        icon: <ShareIcon sx={{ color: 'primary.main' }} fontSize="small" />,
        subLabel: null,
        label: (
            <ListItemText
                primary="Kết nối với chúng tôi"
                primaryTypographyProps={{
                    sx: { color: 'text.primary', fontSize: 14 },
                }}
                secondary={
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={1}>
                        <CustomButton
                            useIconButton
                            disableEffect
                            aria-label="facebook-icon"
                            component="a"
                            href="https://www.facebook.com/nhathuockhanhtrang"
                            children={
                                <CustomAvatar
                                    src="../assets/images/facebook-icon.png"
                                    avatarSize={22}
                                    alt="facebook-icon"
                                    sx={{ backgroundColor: 'white' }}
                                />
                            }
                        />
                        <CustomButton
                            useIconButton
                            disableEffect
                            aria-label="youtube-icon"
                            component="a"
                            href="https://www.youtube.com/@duocsitantam124/about"
                            children={
                                <CustomAvatar
                                    src="../assets/images/youtube-icon.png"
                                    avatarSize={22}
                                    alt="youtube-icon"
                                />
                            }
                        />
                        <CustomButton
                            useIconButton
                            disableEffect
                            aria-label="zalo-icon"
                            component="a"
                            href="https://zalo.me/840599482837287192"
                            children={
                                <CustomAvatar
                                    src="../assets/images/zalo-icon.png"
                                    avatarSize={22}
                                    alt="zalo-icon"
                                />
                            }
                        />
                    </Stack>
                }
                secondaryTypographyProps={{ component: 'div' }}
            />
        ),
    },
];
