import { createContext } from 'react';

export const ProductContext = createContext({
    mainCategoryData: {},
    subCategoryData: {},
    currentCategoryLevel: 1,
    currentCategoryTitle: '',
    isDrawerOpen: false,
    setIsDrawerOpen: () => {},
    viewType: 'vertical',
    toggleViewType: () => {},
    resetAllFilters: () => {},
    applyFilters: () => {},
    // changeFilter: () => {},
    deleteFilter: () => {},
    dataFilter: {},
    filteringChips: [],
    totalProducts: 0,
});
