import { useState, useContext, useMemo } from 'react';

import { Box, Grid2, Link, Stack, Tooltip, Typography } from '@mui/material';
import { useTheme, darken } from '@mui/material/styles';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';

import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

import { ProductContentContext } from 'context/product-content-context';

import { CustomButton, CustomTable } from 'UI';

import Commitment from 'shop/home/Commitment.jsx';
import ProductGallery from './ProductGallery.jsx';

const getDataTable = (itemData, caution) => {
    let {
        category,
        dosageForm,
        specification,
        ingredients_raw,
        mainUse,
        manuValue,
        attribute,
        licenses,
    } = itemData;

    let isPrescription = category?.[1]?.id?.startsWith('01-1')
        ? 'Có'
        : category?.[1]?.id?.startsWith('01-2')
        ? 'Không'
        : '';

    let contact = (
        <span>
            <b>{'Liên hệ Zalo: '}</b>
            <a
                href="tel:0704430919"
                style={{
                    color: '#1E90FF',
                    textDecoration: 'none',
                }}>
                070.443.0919
            </a>
        </span>
    );

    return [
        [<b>Giá tham khảo</b>, contact],
        [
            'Danh mục',
            <Link
                component={RouterLink}
                underline="none"
                sx={{
                    color: '#1E90FF',
                    ':hover': {
                        color: '#0056B3',
                    },
                }}
                to={
                    category[0].slug +
                    (category.length > 1
                        ? category[category.length - 1].slug
                        : '')
                }>
                {category?.[category.length - 1]?.name ?? category[0].name}
            </Link>,
        ],
        ['Dạng bào chế', dosageForm.join(', ')],
        ['Quy cách', specification],
        ['Thành phần', ingredients_raw],
        ['Công dụng', mainUse],
        [
            'Nhà sản xuất',
            <Link
                component={RouterLink}
                underline="none"
                sx={{
                    color: '#1E90FF',
                    ':hover': {
                        color: '#0056B3',
                    },
                }}
                to={'/tim-kiem?s=' + encodeURIComponent(manuValue.manuName)}>
                {manuValue.manuName}
            </Link>,
        ],
        ['Nước sản xuất', manuValue.manuNation],
        ['Mô tả sản phẩm', attribute],
        ['Số đăng ký', licenses],
        ['Thuốc cần kê toa', isPrescription],
        [<b>Lưu ý</b>, <b>{caution}</b>],
    ].filter((i) => !!i[1]);
};

const StyledTypography = ({ children, ...otherProps }) => {
    return (
        <Typography
            sx={{
                fontWeight: 500,
                fontSize: { xs: 14, sm: 16 },
                minWidth: { xs: 100, sm: 140 },
            }}
            {...otherProps}>
            {children}
        </Typography>
    );
};

function ProductInfo({ ...props }) {
    const { itemData, caution } = useContext(ProductContentContext);

    const [tooltipOpen, setTooltipOpen] = useState(false);

    let { sku, webName, isActive, modifiedAt } = itemData;

    const handleCopySku = async (data) => {
        try {
            await navigator.clipboard.writeText(data);
            setTooltipOpen(true);
            setTimeout(() => {
                setTooltipOpen(false);
            }, 3000);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    let tableRowData = useMemo(
        () => getDataTable(itemData, caution),
        [caution, itemData]
    );

    return (
        <>
            <Typography
                variant="subtitle2"
                sx={{
                    color: 'error.light',
                    my: 1,
                    pl: 1,
                }}>
                Sản phẩm cần tư vấn từ dược sỹ
            </Typography>
            <Typography
                variant="h1"
                sx={{
                    color: 'text.primary',
                    pl: 1,
                    pr: 2,
                    fontSize: { xs: '18px', sm: '24px' },
                    lineHeight: { xs: '1.5rem', sm: '2.25rem' },
                    letterSpacing: '0.01em',
                    fontWeight: 500,
                }}>
                {webName}
            </Typography>
            <Stack
                sx={{ my: 1, pl: 1 }}
                direction={{ xs: 'colum', sm: 'row' }}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                justifyContent="flex-start"
                spacing={0.5}>
                <Tooltip
                    title="Đã sao chép vào bộ nhớ tạm"
                    placement="top"
                    open={tooltipOpen}
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -14],
                                    },
                                },
                            ],
                        },
                    }}>
                    <div>
                        <CustomButton
                            component="div"
                            id="sku-copy-custom-button"
                            color="disabled"
                            disableEffect
                            sx={{
                                minWidth: 0,
                                p: 0,
                                color: 'text.secondary',
                            }}
                            endIcon={
                                <ContentCopyIcon
                                    sx={{
                                        color: 'text.primary',
                                        width: 16,
                                        height: 16,
                                        ':hover': {
                                            color: 'success.main',
                                        },
                                    }}
                                />
                            }
                            onClick={() => handleCopySku(sku)}
                            children={sku}
                        />
                    </div>
                </Tooltip>
                <Typography variant="caption">
                    {' • Cập nhật: '}
                    {moment(modifiedAt).format('DD-MM-YYYY')}
                    {isActive ? ' • Đang kinh doanh' : ' • Ngừng kinh doanh'}
                </Typography>
            </Stack>
            <Box pr={3}>
                <CustomTable
                    id="product-data-tableId"
                    columns={[
                        { key: 'columnKey1', label: 'label-column-1' },
                        { key: 'columnKey2', label: 'label-column-2' },
                    ]}
                    data={tableRowData.map(([columnCell1, columnCell2]) => ({
                        columnKey1: {
                            value: (
                                <StyledTypography>
                                    {columnCell1}
                                </StyledTypography>
                            ),
                            sx: {
                                borderBottom: { sm: 'none' },
                                padding: { xs: 0.5, sm: 1 },
                            },
                        },
                        columnKey2: {
                            value: (
                                <StyledTypography>
                                    {columnCell2}
                                </StyledTypography>
                            ),
                            sx: {
                                borderBottom: { sm: 'none' },
                                padding: { xs: 0.5, sm: 1 },
                            },
                        },
                    }))}
                    getTableRowStyle={(rowIndex) => ({
                        verticalAlign: 'top',
                        backgroundColor: 'transparent !important',
                    })}
                    TableContainerProps={{ component: 'div' }}
                    showHeader={false}
                />
            </Box>
        </>
    );
}

export default function ProductDataTable({ ...props }) {
    const { itemData } = useContext(ProductContentContext);

    if (!itemData) {
        return null;
    }

    return (
        <Box
            width="100%"
            pt={2}
            sx={{
                backgroundColor: 'white',
                borderRadius: { xs: 0, sm: 2 },
            }}>
            <Grid2 container spacing={2} sx={{ width: '100%' }}>
                <Grid2 size={{ xs: 12, lg: 5 }}>
                    <ProductGallery />
                </Grid2>
                <Grid2
                    size={{ xs: 12, lg: 7 }}
                    sx={{
                        maxWidth: 'calc(100% - 16px)',
                        pb: 2,
                        pl: { xs: 2, lg: 0 },
                    }}>
                    <ProductInfo />
                </Grid2>
                <Grid2
                    size={{ xs: 12 }}
                    sx={{
                        backgroundColor: 'whitesmoke',
                        display: { xs: 'none', sm: 'block' },
                        borderRadius: '0px 0px 8px 8px',
                    }}>
                    <Commitment />
                </Grid2>
            </Grid2>
        </Box>
    );
}
