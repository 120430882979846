import { FilterListOutlined as FilterListOutlinedIcon } from '@mui/icons-material';

import { CustomButton } from 'UI';

export default function FilterButton({ onClick = () => {}, ...otherProps }) {
    return (
        <CustomButton
            id={'header-filter-button'}
            variant="outlined"
            color="disabled"
            onClick={onClick}
            sx={{
                borderRadius: 5,
                borderWidth: '0.5px',
                borderStyle: 'solid',
                borderColor: 'disabled.light',
                display: { lg: 'none' },
                '&:active': {
                    boxShadow: 'none',
                    backgroundColor: 'white',
                },
                '&:focus': {
                    boxShadow: 'none',
                    backgroundColor: 'white',
                },
            }}
            startIcon={<FilterListOutlinedIcon />}
            children="Lọc"
        />
    );
}
