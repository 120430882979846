import { KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon } from '@mui/icons-material';
import { CustomButton } from 'UI';

export default function EndOfListWithFetchMore({
    isFetching = false,
    title = 'Xem thêm',
    fetchNextPage = () => {},
    ...props
}) {
    return (
        <CustomButton
            id="see-more-results-buttonId"
            color="disabled"
            fullWidth
            disableEffect
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                p: 0,
                mb: { xs: 1, sm: 2 },
            }}
            onClick={(event) => {
                event.preventDefault();
                fetchNextPage();
                event.currentTarget.blur();
            }}
            startIcon={!isFetching && <KeyboardDoubleArrowDownIcon />}
            children={isFetching ? 'Đang tải...' : title}
        />
    );
}
