import { useState, useMemo, useCallback, useEffect } from 'react';
import { Stack, Box } from '@mui/material';

import { useParams, useLoaderData, useLocation } from 'react-router-dom';
import { useInfiniteQuery, keepPreviousData } from '@tanstack/react-query';
import { postAdvancedQuery } from 'utils/http.js';

import cateLv1Json from 'data/cateLv1.json';
import cateLv2Json from 'data/cateLv2.json';
import cateLv3Json from 'data/cateLv3.json';

import { CustomHelmet, CustomMasonry } from 'UI';
import { LoadingModal } from 'template';
import { ProductContext } from 'context/product-context.js';

import SectionBreadcrumbs from '../../layout/section/SectionBreadcrumbs.jsx';
import Page404 from '../404/Page404.jsx';

import Header from './subComponents/Header.jsx';
import FilterBox from './subComponents/FilterBox.jsx';

import ActiveFilterBox from 'shop/components/ActiveFilterBox.jsx';
import NotFoundData from 'shop/components/NotFoundData.jsx';
import EndOfListWithFetchMore from 'shop/components/EndOfListWithFetchMore.jsx';
import EndOfListWithClearFilter from 'shop/components/EndOfListWithClearFilter.jsx';
import PageProductSkeleton from 'shop/components/PageProductSkeleton.jsx';

import ProductCard from './ProductCard.jsx';

const PAGE_SIZE = 12;

export default function PageProductList({ ...otherProps }) {
    let location = useLocation();
    const { mainCategorySlug, subCategorySlug } = useParams();
    const {
        mainCategoryData,
        subCategoryData,
        currentCategoryLevel,
        currentCategoryTitle,
        breadcrumbsData,
    } = useLoaderData();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [viewType, setViewType] = useState('vertical');
    const [activeFilters, setActiveFilters] = useState({
        manuName: [],
        manuNation: [],
        administration: [],
        dosageForm: [],
        prescription: [],
        prodCate: [],
    });

    const {
        data,
        isLoading, // True only on the initial load.
        isFetching, // True when fetching new data (subsequent requests).
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: [
            'product-item',
            {
                mainCategorySlug,
                subCategorySlug,
                activeFilters: JSON.stringify(activeFilters),
            },
        ],
        queryFn: ({ pageParam = 0, signal }) =>
            postAdvancedQuery({
                signal,
                filterModel: Object.keys(activeFilters).map((key) => ({
                    field: key,
                    value: activeFilters[key],
                })),
                pageSize: PAGE_SIZE,
                page: pageParam + 1,
                apiUrl: `/ecom/product-item/filter?${
                    mainCategorySlug
                        ? `mainCategorySlug=${mainCategorySlug}&`
                        : ''
                }${
                    subCategorySlug ? `subCategorySlug=${subCategorySlug}&` : ''
                }type=1`,
            }),
        getNextPageParam: (lastPage, allPages) => {
            const totalFetchedItems = allPages.flatMap(
                (page) => page.data
            ).length;

            return totalFetchedItems < lastPage.totalCount
                ? totalFetchedItems
                : undefined;
        },
        placeholderData: keepPreviousData,
        staleTime: 30000,
        retry: 0,
        enabled: Boolean(mainCategoryData),
    });

    // const changeFilter = useCallback((keyName, newData) => {
    //     setActiveFilters((prevValue) => {
    //         let updatedValue = { ...prevValue };

    //         if (typeof newData === 'string' && newData === 'Tất cả') {
    //             return { ...updatedValue, [keyName]: [] };
    //         } else {
    //             let updatedFilter = [...updatedValue[keyName]];
    //             updatedFilter = !newData.checked
    //                 ? updatedFilter.filter((i) => i !== newData.name)
    //                 : updatedFilter.concat([newData.name]);
    //             return { ...updatedValue, [keyName]: updatedFilter };
    //         }
    //     });
    // }, []);
    const applyFilters = useCallback((keyName, newData) => {
        setActiveFilters((prevValue) => {
            let updatedValue = { ...prevValue };
            return {
                ...updatedValue,
                [keyName]: newData
                    .filter((i) => i.key === keyName)
                    .map((i) => i.chip),
            };
        });
    }, []);

    const deleteFilter = useCallback(({ key, value }) => {
        setActiveFilters((prevValue) => {
            let updatedValue = { ...prevValue };
            let updatedFilter = [...updatedValue[key]];

            updatedValue[key] = updatedFilter.filter((i) => i !== value);
            return { ...updatedValue };
        });
    }, []);

    const resetAllFilters = useCallback(() => {
        setActiveFilters({
            manuName: [],
            manuNation: [],
            administration: [],
            dosageForm: [],
            prescription: [],
            prodCate: [],
        });
    }, []);

    const dataFilter = useMemo(() => {
        return {
            prodCate: data?.pages?.[0]?.prodCates || [],
            manuName: data?.pages?.[0]?.manufactures || [],
            manuNation: data?.pages?.[0]?.nations || [],
            prescription: data?.pages?.[0]?.prescriptions || [],
            administration: data?.pages?.[0]?.administrations || [],
            dosageForm: data?.pages?.[0]?.dosageForms || [],
        };
    }, [data?.pages]);

    const mergedProducts = data?.pages?.flatMap((page) => page.data) || [];
    const totalLoadedProducts = mergedProducts.length;
    const totalProducts = parseInt(data?.pages?.[0]?.totalCount || 0);

    let filteringChips = Object.keys(activeFilters).reduce((acc, key) => {
        acc = acc.concat(
            activeFilters[key].map((i) => ({
                chip: i,
                key,
                onDelete: () => deleteFilter({ key, value: i }),
            }))
        );
        return acc;
    }, []);

    const contextValue = useMemo(
        () => ({
            mainCategoryData,
            subCategoryData,
            currentCategoryLevel,
            currentCategoryTitle,
            isDrawerOpen,
            setIsDrawerOpen,
            viewType,
            toggleViewType: () =>
                setViewType((v) =>
                    v === 'vertical' ? 'horizontal' : 'vertical'
                ),

            dataFilter,
            resetAllFilters,
            // changeFilter,
            applyFilters,
            deleteFilter,
            totalProducts,
            filteringChips,
        }),
        [
            applyFilters,
            // changeFilter,
            currentCategoryLevel,
            currentCategoryTitle,
            dataFilter,
            deleteFilter,
            filteringChips,
            isDrawerOpen,
            mainCategoryData,
            resetAllFilters,
            subCategoryData,
            totalProducts,
            viewType,
        ]
    );

    if (
        (mainCategorySlug && !mainCategoryData) ||
        (subCategorySlug && !subCategoryData)
    ) {
        return <Page404 />;
    }

    if (isLoading) {
        return <PageProductSkeleton />;
    }

    return (
        <>
            <CustomHelmet
                title={`${currentCategoryTitle} chính hãng, giá tốt - Nhà thuốc Khánh Trang`}
                description={`Mua ${currentCategoryTitle.toLowerCase()} chính hãng, giá tốt tại nhà thuốc Khánh Trang`}
                slug={`https://nhathuockhanhtrang.com.vn${location.pathname}`}
            />
            <ProductContext.Provider value={contextValue}>
                <Stack sx={{ maxWidth: 1200, mx: 'auto' }}>
                    <LoadingModal isLoading={isFetching && !isDrawerOpen} />
                    <Box
                        sx={{
                            mx: 2,
                            px: 2,
                            mt: { xs: 3, md: 2 },
                            pt: { xs: 1, sm: 2, lg: 1 },
                            backgroundColor: { xs: 'white', lg: 'transparent' },
                            borderRadius: {
                                xs: '10px 10px 0px 0px',
                                lg: '0px',
                            },
                        }}>
                        <SectionBreadcrumbs breadcrumbsData={breadcrumbsData} />
                    </Box>
                    <Stack
                        direction="row"
                        alignItems="stretch"
                        pt={{ xs: 0, lg: 2 }}
                        pl={{ xs: 0, lg: 2 }}
                        pb={{ xs: 0, lg: 2 }}
                        spacing={{ xs: 0, lg: 0.5 }}>
                        <FilterBox />
                        <div style={{ width: '100%' }}>
                            <Box px={2} pb={{ xs: 1, sm: 0 }}>
                                <Header />
                                {filteringChips.length > 0 && (
                                    <Box
                                        sx={{
                                            backgroundColor: 'white',
                                            borderRadius: '0px 0px 10px 10px',
                                        }}>
                                        <ActiveFilterBox
                                            chips={filteringChips}
                                            onClickResetButton={resetAllFilters}
                                        />
                                    </Box>
                                )}
                            </Box>
                            {totalLoadedProducts === 0 ? (
                                <NotFoundData
                                    type="product"
                                    isFiltering={filteringChips.length > 0}
                                    onClearFilters={resetAllFilters}
                                />
                            ) : (
                                <Box px={{ xs: 1, sm: 0 }}>
                                    <CustomMasonry
                                        spacing={{ xs: 1, sm: 2 }}
                                        elevation={1}
                                        id="page-product-list-masonry-id"
                                        columns={
                                            viewType === 'vertical'
                                                ? { xs: 2, lg: 3, xl: 4 }
                                                : { xs: 1, md: 2, lg: 2, xl: 2 }
                                        }
                                        items={mergedProducts.map(
                                            (prodData) => ({
                                                id: prodData._id,
                                                content: (
                                                    <ProductCard
                                                        prodData={prodData}
                                                        viewType={viewType}
                                                    />
                                                ),
                                            })
                                        )}
                                    />
                                </Box>
                            )}
                            {hasNextPage ? (
                                <EndOfListWithFetchMore
                                    isFetching={isFetchingNextPage}
                                    title={`Xem thêm ${Number(
                                        totalProducts - totalLoadedProducts
                                    ).toLocaleString()} sản phẩm`}
                                    fetchNextPage={fetchNextPage}
                                />
                            ) : (
                                totalProducts > PAGE_SIZE && (
                                    <EndOfListWithClearFilter
                                        isFiltering={filteringChips.length > 0}
                                        onClearFilters={resetAllFilters}
                                    />
                                )
                            )}
                        </div>
                    </Stack>
                </Stack>
            </ProductContext.Provider>
        </>
    );
}

export const loader = ({ params }) => {
    let { mainCategorySlug, subCategorySlug } = params;
    const findMainCategory =
        cateLv1Json.find((item) => item.slug === '/' + mainCategorySlug) ||
        null;
    const findSubCategory =
        [...cateLv2Json, ...cateLv3Json].find(
            (item) => item.slug === '/' + subCategorySlug
        ) || null;
    const currentCategoryLevel = Number(findSubCategory?.level ?? 1);
    let breadcrumbsData = [
        {
            to: `/${mainCategorySlug}`,
            name: findMainCategory?.name || '',
            isNavLink: currentCategoryLevel !== 1,
        },
    ];
    if (currentCategoryLevel === 2) {
        breadcrumbsData.push({
            to: `/${mainCategorySlug}/${subCategorySlug}`,
            name: findSubCategory.name,
            isNavLink: false,
        });
    }
    if (currentCategoryLevel === 3) {
        const parentCategory = cateLv2Json.find(
            (item) => item.name === findSubCategory.parentName
        );
        breadcrumbsData.push(
            {
                to: `/${mainCategorySlug}${parentCategory.slug}`,
                name: findSubCategory.parentName,
            },
            {
                to: `/${mainCategorySlug}/${subCategorySlug}`,
                name: findSubCategory.name,
                isNavLink: false,
            }
        );
    }
    let currentCategoryTitle = findSubCategory
        ? findSubCategory.name
        : findMainCategory
        ? findMainCategory.name
        : 'Thuốc';
    return {
        mainCategoryData: findMainCategory,
        subCategoryData: findSubCategory,
        currentCategoryLevel,
        currentCategoryTitle,
        breadcrumbsData,
    };
};
